import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  firebaseObject,
  getApplicants,
  getInterviews,
  getJobs,
  getUsers,
} from "../../redux/actions/firebaseActions";
import { store } from "../../redux/store";

const FirebaseContext = React.createContext(null);

export const withFirebase = (Component) => (props) => {
  const dispatch = useDispatch();
  const firebase = store.getState().firebaseReducer.firebase;
  useEffect(() => {
    dispatch(getJobs());
    dispatch(getApplicants());
    dispatch(getUsers());
    dispatch(getInterviews());
  }, [firebase, dispatch]);

  return (
    <FirebaseContext.Consumer>
      {(firebase) => {
        dispatch(firebaseObject(firebase));
        return <Component {...props} firebase={firebase} />;
      }}
    </FirebaseContext.Consumer>
  );
};

export default FirebaseContext;
