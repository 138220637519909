const publicLink = process.env.PUBLIC_URL;

const content = {
  vectors: {
    about: publicLink + "/assets/vectors/about.png",
    attachment: publicLink + "/assets/vectors/attachment.png",
    connections: publicLink + "/assets/vectors/connections.png",
    events: publicLink + "/assets/vectors/events.png",
    experience: publicLink + "/assets/vectors/experience.png",
    expertise: publicLink + "/assets/vectors/expertise.png",
    landscape: publicLink + "/assets/vectors/landscape.png",
    leaderboard: publicLink + "/assets/vectors/leaderboard.png",
    locationOrange: publicLink + "/assets/vectors/locationOrange.png",
    paragraph: publicLink + "/assets/vectors/paragraph.png",
    location: publicLink + "/assets/vectors/location.png",
    smile: publicLink + "/assets/vectors/smile.png",
    sort: publicLink + "/assets/vectors/sort.png",
    teammate: publicLink + "/assets/vectors/teammate.png",
    video: publicLink + "/assets/vectors/video.png",
    x: publicLink + "/assets/vectors/x.png",
    trello: publicLink + "/assets/trello.png",
    list: publicLink + "/assets/list.png",
    copyIcon: publicLink + "/assets/vectors/copy-icon.svg",
    checkedboxIcon: publicLink + "/assets/vectors/checked-checkbox.png",
    uncheckedboxIcon: publicLink + "/assets/vectors/uncheck-checkbox.png",
    salaryOrangeIcon: publicLink + "/assets/vectors/salary-orange-icon.svg",
    assessmentIcon: publicLink + "/assets/vectors/assessmentIcon.png",
    interviewIcon: publicLink + "/assets/vectors/interview-icon.png",
    resumeRateIcon: publicLink + "/assets/vectors/resume-rate-icon.png",
    aiAssistantButtonIcon: publicLink + "/assets/vectors/AI-button-icon.png",
    targating: publicLink + "/assets/vectors/targating.svg",
    moneyWavy: publicLink + "/assets/vectors/moneyWavy.svg",
    flowArrow: publicLink + "/assets/vectors/flowArrow.svg",
    PerformanceMetrics: publicLink + "/assets/vectors/PerformanceMetrics.svg",
    loadingSpinner: publicLink + "/assets/vectors/loadingSpinner.svg",
    success: publicLink + "/assets/vectors/success.svg",
    error: publicLink + "/assets/vectors/error.svg",
    emptyList: publicLink + "/assets/vectors/emptyList.svg",
    downloadIcon: publicLink + "/assets/vectors/downloadIcon.svg",
    ongoingCallIcon: publicLink + "/assets/vectors/ongoingCallIcon.svg",
    playIcon: publicLink + "/assets/vectors/playIcon.svg",
    pauseIcon: publicLink + "/assets/vectors/pauseIcon.svg",
    callIcon: publicLink + "/assets/vectors/callIcon.svg",
    emailIcon: publicLink + "/assets/vectors/emailIcon.svg",
    viewCVIcon: publicLink + "/assets/vectors/viewCVIcon.svg",
    viewTranscriptIcon: publicLink + "/assets/vectors/viewTranscriptIcon.svg",
    selectAvtar: publicLink + "/assets/vectors/selectAvtar.svg",
    selectAvtarActive: publicLink + "/assets/vectors/selectAvtarActive.svg",
    share: publicLink + "/assets/vectors/share.svg",
    shareActive: publicLink + "/assets/vectors/shareActive.svg",
    knowledgeBase: publicLink + "/assets/vectors/knowledgeBase.svg",
    knowledgeBaseActive: publicLink + "/assets/vectors/knowledgeBaseActive.svg",
    playCircle: publicLink + "/assets/vectors/playCircle.svg",
    samantha: publicLink + "/assets/vectors/Samantha.png",
    matthew: publicLink + "/assets/vectors/Matthew.png",
    duncan: publicLink + "/assets/vectors/Duncan.png",
    emma: publicLink + "/assets/vectors/Emma.png",
    alex: publicLink + "/assets/vectors/Alex.png",
    alice: publicLink + "/assets/vectors/Alice.png",
    delete: publicLink + "/assets/vectors/delete-icon.svg",
    lookUp: publicLink + "/assets/vectors/lookup-icon.svg"
  },
  subHeaders: {
    dashboard: publicLink + "/assets/subheader/homeIcon.svg",
    applicant: publicLink + "/assets/subheader/applicant.svg",
    interview: publicLink + "/assets/subheader/interview.svg",
    job: publicLink + "/assets/subheader/jobBag.svg",
    message: publicLink + "/assets/subheader/message.svg",
    setting: publicLink + "/assets/subheader/setting.svg",
    profile: publicLink + "/assets/subheader/profile.svg",
    network: publicLink + "/assets/subheader/network.svg",
    referral: publicLink + "/assets/subheader/referral.svg",
    recruiter: publicLink + "/assets/subheader/recruiter.svg",
  },
  footer: {
    facebook: publicLink + "/assets/footer/facebook.svg",
    linkedin: publicLink + "/assets/footer/Linkedin.svg",
    twitter: publicLink + "/assets/footer/twitter.svg",
  },
  slider: {
    arup: publicLink + "/assets/slider/Arup.png",
    citrix: publicLink + "/assets/slider/citrix.png",
    cofco: publicLink + "/assets/slider/cofco.jpg",
    czarnikow: publicLink + "/assets/slider/czarnikow.png",
    globalmind: publicLink + "/assets/slider/globalmind.jpg",
    weebly: publicLink + "/assets/slider/weebly.png",
    sliderRightArrow: publicLink + "/assets/slider/slider-right-arrow.png",
    sliderLeftArrow: publicLink + "/assets/slider/slider-left-arrow.png",
  },
  placeHolder: {
    jobDetails: publicLink + "/assets/placeholder/job-details-placeholder.png",
    landingPageImage1:
      publicLink + "/assets/placeholder/landing-page-image-1.png",
    landingPageImage2:
      publicLink + "/assets/placeholder/landing-page-image-2.png",
    landingPageImage3:
      publicLink + "/assets/placeholder/landing-page-image-3.png",
  },
  playBtn: {
    img: publicLink + "/assets/play-btn.png",
  },
  landingPageVideoURL:
    "https://firebasestorage.googleapis.com/v0/b/candid-i-dcb95.appspot.com/o/videos%2F1707111111090?alt=media&token=649499b4-cf52-4248-b80f-caaac9f49df5",

  closeBtn: {
    img: publicLink + "/assets/close-btn.png",
  },
  createJob: {
    aiAssistant: publicLink + "/assets/ai-assistant.svg",
    jobdescriptionGenerator:
      publicLink + "/assets/job-description-generator.svg",
  },
  footerHeroImage: publicLink + "/assets/footer-hero-image.png",

  companyProfile: {
    image: publicLink + "/assets/profile/company-img.png",
    cover: publicLink + "/assets/profile/profile-cover.png",
    companyName: "Xiaomi Incorporated",
    isVerified: true,
    location: "Beijing, China",
    category: "Technology",
    followers: 115900,
    about:
      "Xiaomi has 18,170 employees worldwide. It has expanded to other markets including Greater China, Singapore, Japan, South Korea, Russia, South Africa and most countries and regions in Southeast Asia and Europe. According to Forbes, Lei Jun, the founder and CEO, has an estimated net worth of US$12.5 billion. Xiaomi is the world's 4th most valuable technology start-up after receiving US$1.1 billion funding from investors.",
    website: "www.mi.com",
    headquarters: "Haidian District, Beijing, China",
    viewed: [
      {
        img: publicLink + "/assets/profile/viewed/fuse.png",
        name: "Fuse",
        followers: 115900,
      },
      {
        img: publicLink + "/assets/profile/viewed/gowork.png",
        name: "GoWork",
        followers: 115900,
      },
      {
        img: publicLink + "/assets/profile/viewed/kulina.png",
        name: "Kulina",
        followers: 115900,
      },
      {
        img: publicLink + "/assets/profile/viewed/ematic.png",
        name: "Ematic",
        followers: 115900,
      },
      {
        img: publicLink + "/assets/profile/viewed/halodoc.png",
        name: "Halodoc",
        followers: 115900,
      },
      {
        img: publicLink + "/assets/profile/viewed/amartha.png",
        name: "Amartha",
        followers: 115900,
      },
      {
        img: publicLink + "/assets/profile/viewed/codebrick.png",
        name: "Codebrick",
        followers: 115900,
      },
    ],
    posts: [
      {
        title: "How Xiaomi Approaches Innovation",
        img: publicLink + "/assets/profile/posts/post1.png",
        url: "xiaomi.com",
        length: "5 min.",
        likes: 114,
        comments: 13,
        isLiked: true,
        datePosted: "3d ago",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque",
      },
      {
        title: "How Xiaomi Approaches Innovation",
        img: publicLink + "/assets/profile/posts/post2.png",
        url: "xiaomi.com",
        length: "5 min.",
        likes: 113,
        comments: 13,
        isLiked: false,
        datePosted: "3d ago",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque",
      },
    ],
    latestJobs: [
      {
        position: "Senior Business Analyst",
        location: "Singapore",
        jobType: "Full Time",
        fee: "$500",
      },
      {
        position: "Junior Level UI / UX Designer",
        location: "Singapore",
        jobType: "Full Time",
        fee: "$500",
      },
      {
        position: "Sales Executive",
        location: "Singapore",
        jobType: "Full Time",
        fee: "$500",
      },
    ],
    videos: [
      {
        img: publicLink + "/assets/profile/videos/video1.png",
        title: "Company Profile",
        length: "5 min. 27 sec.",
      },
      {
        img: publicLink + "/assets/profile/videos/video2.png",
        title: "Introduction To Our New SOC",
        length: "5 min. 27 sec.",
      },
    ],
    rightArrow: publicLink + "/assets/profile/viewed/right-arrow.png",
    playVector: publicLink + "/assets/profile/videos/play-vector.png",
    ellipse: publicLink + "/assets/profile/posts/ellipse.png",
  },

  modal: {
    svg: process.env.PUBLIC_URL + "/assets/applicationSubmitted.svg",
  },

  pdf: {
    react: process.env.PUBLIC_URL + "/assets/react.pdf",
  },

  sample: {
    img: process.env.PUBLIC_URL + "/android-chrome-192x192.png",
  },

  defaultUser: {
    img: process.env.PUBLIC_URL + "/assets/default-user.jpg",
  },

  defaultPlaceholder: {
    img: process.env.PUBLIC_URL + "/assets/default-placeholder.png",
  },

  logo: publicLink + "/assets/candid-i-logo.png",
  whiteLogo: publicLink + "/assets/white-candid-i-logo.png",

  companies: {
    cloudflare: publicLink + "/assets/companies/logo-cloudflare.png",
    facebook: publicLink + "/assets/companies/logo-facebook.png",
    fedex: publicLink + "/assets/companies/logo-fedex.png",
    google: publicLink + "/assets/companies/logo-google.png",
    xiaomi: publicLink + "/assets/companies/logo-xiaomi.png",
  },

  rating: {
    stars0: publicLink + "/assets/rating/stars0",
    stars01: publicLink + "/assets/rating/stars01",
    stars1: publicLink + "/assets/rating/stars1",
    stars12: publicLink + "/assets/rating/stars12",
    stars2: publicLink + "/assets/rating/stars2",
    stars23: publicLink + "/assets/rating/stars23",
    stars3: publicLink + "/assets/rating/stars3",
    stars34: publicLink + "/assets/rating/stars34",
    stars4: publicLink + "/assets/rating/stars4",
    stars45: publicLink + "/assets/rating/stars45",
    stars5: publicLink + "/assets/rating/stars-5.png",
  },

  samplePdf: publicLink + "/assets/sample-pdf.png",

  dashboard: {
    recruiter: {
      img: publicLink + "/assets/users/jonathan-wong.png",
      name: "Jonathan Wong",
      activeJobs: 20,
      interviews: 40,
    },
    hiringEmployers: [
      {
        img: publicLink + "/assets/companies/logo-google.png",
        position: "Team Lead",
        company: "Google, Inc.",
        postDate: "14 Jun 2019",
        expiryDate: "25 Sep 2019",
        location: "Singapore",
        jobType: "Permanent",
        salary: "80K SGD",
        recruiterFee: "5.6K SGD",
        postedBy: "Dianne Wilson",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        responsibilities: [
          "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam",
          "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit",
          "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex",
        ],
        requirements: [
          "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam",
          "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit",
          "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex",
        ],
        keywords: [
          "Blockchain",
          "Ximarin Development",
          "Artificial Intelligence",
          "Healthcare",
        ],
      },
      {
        img: publicLink + "/assets/companies/logo-facebook.png",
        position: "Team Lead",
        company: "Facebook",
        postDate: "14 Jun 2019",
        expiryDate: "25 Sep 2019",
      },
      {
        img: publicLink + "/assets/companies/logo-cloudflare.png",
        position: "Web Developer",
        company: "Cloudflare",
        postDate: "14 Jun 2019",
        expiryDate: "25 Sep 2019",
      },
    ],
    employer: {
      img: publicLink + "/assets/users/leslie-alexander.png",
      name: "Leslie Alexander",
      activeJobs: 20,
      interviews: 40,
      direct: 25,
      referral: 45,
      recruiters: 30,
    },
    candidatesForInterview: [
      {
        img: publicLink + "/assets/users/jacob-xing.png",
        candidate: "Jacob Xing",
        position: "Project Manager",
        interviewer: "Dianne Wilson",
        date: "7 Jul 2019",
      },
      {
        img: publicLink + "/assets/users/sally-tool.png",
        candidate: "Sally Tool",
        position: "Business Analyst",
        interviewer: "Rebecca Tan",
        date: "10 Jul 2019",
      },
    ],
    candidatesForReview: [
      {
        img: publicLink + "/assets/users/ronald-richards.png",
        candidate: "Ronald Richards",
        location: "Singapore",
        position: "Marketing Coordinator",
        date: "7 Jul 2019",
        isRecommended: true,
      },
      {
        img: publicLink + "/assets/users/jacob-jones.png",
        candidate: "Jacob Jones",
        location: "Singapore",
        position: "Web Designer",
        date: "7 Jul 2019",
        isRecommended: true,
      },
      {
        img: publicLink + "/assets/users/albert-flores.png",
        candidate: "Albert Flores",
        location: "Singapore",
        position: "Team Lead",
        date: "7 Jul 2019",
        isRecommended: true,
      },
    ],
    faveRecruiters: [
      {
        img: publicLink + "/assets/users/arlene-hawkins.png",
        name: "Arlene Hawkins",
        location: "New York, NY",
        keywords: ["Blockchain", "IT Recruiter", "Artificial Intelligence"],
        rating: 4.6,
        isFavorite: true,
      },
      {
        img: publicLink + "/assets/users/robert-fox.png",
        name: "Robert Fox",
        location: "New York, NY",
        keywords: ["Blockchain", "IT Recruiter", "Artificial Intelligence"],
        rating: 4.6,
        isFavorite: true,
      },
      {
        img: publicLink + "/assets/users/esther-howard.png",
        name: "Esther Howard",
        location: "New York, NY",
        keywords: ["Blockchain", "IT Recruiter", "Artificial Intelligence"],
        rating: 4.6,
        isFavorite: true,
      },
      {
        img: publicLink + "/assets/users/arlene-mccoy.png",
        name: "Arlene McCoy",
        location: "New York, NY",
        keywords: ["Blockchain", "IT Recruiter", "Artificial Intelligence"],
        rating: 4.6,
        isFavorite: true,
      },
      {
        img: publicLink + "/assets/users/kathryn-murphy.png",
        name: "Kathryn Murphy",
        location: "New York, NY",
        keywords: ["Blockchain", "IT Recruiter", "Artificial Intelligence"],
        rating: 4.6,
        isFavorite: true,
      },
      {
        img: publicLink + "/assets/users/courtney-henry.png",
        name: "Courtney Henry",
        location: "New York, NY",
        keywords: ["Blockchain", "IT Recruiter", "Artificial Intelligence"],
        rating: 4.6,
        isFavorite: true,
      },
      {
        img: publicLink + "/assets/users/brooklyn-simmons.png",
        name: "Brooklyn Simmons",
        location: "New York, NY",
        keywords: ["Blockchain", "IT Recruiter", "Artificial Intelligence"],
        rating: 4.6,
        isFavorite: true,
      },
    ],
  },

  recruiter: {
    noRecruiterSelected: publicLink + "/assets/no-recruiter-selected.svg",
    profilePhoto: publicLink + "/assets/users/philip-hawkins.png",
    fullName: "Brooklyn Simmons",
    location: "New York, NY",
    address:
      "3763 W Dallas St., Simi Valley, New Hampshire, 91758 United States",
    keywords: ["Blockchain", "IT Recruiter", "Artificial Intelligence"],
    rating: 4.6,
    isFavorite: true,
    candidates: 752,
    interviews: 650,
    jobOffers: 247,
    email: "bsimmons@email.com",
    about:
      "I'm an experienced recruiter with 7 years experience helping candidates identify the best career. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.",
    phoneNumber: "(219) 555-0114",
    socialMedia: [
      {
        platform: "Skype",
        // logo: publicLink + "/assets/social/skype.png",
        logo: "FaFacebookSquare",
        name: "Brooklyn Simmons",
        link: "brooklyn.simmons",
      },
      {
        platform: "LinkedIn",
        // logo: publicLink + "/assets/social/linkedin.png",
        logo: "FaFacebookSquare",
        name: "Brooklyn Simmons",
        link: "www.linkedin.com/b.simmons",
      },
      {
        platform: "Facebook",
        // logo: publicLink + "/assets/social/facebook.png",
        logo: "FaFacebookSquare",
        name: "Brooklyn Simmons",
        link: "www.facebook.com/b.simmons",
      },
    ],
  },

  jobPositionsEmployer: [
    {
      title: "Team Lead",
      applications: 87,
      status: "Posted",
      location: "Singapore",
      expiryDate: "10 Sep 2019",
      created: "7 Jul 2019",
      recruiterFee: 3000,
      referralFee: 100,
    },
    {
      title: "Angular JS Developer",
      applications: 6,
      status: "Closed",
      location: "Hong Kong",
      expiryDate: "22 Sep 2019",
      created: "23 Jul 2019",
      recruiterFee: 7000,
      referralFee: 75,
    },
    {
      title: "SEO Expert",
      applications: 78,
      status: "Posted",
      location: "Jakarta",
      expiryDate: "14 Sep 2019",
      created: "28 Jun 2019",
      recruiterFee: 3500,
      referralFee: 125,
    },
    {
      title: "PM/QA Team Lead",
      applications: 75,
      status: "Posted",
      location: "Tokyo",
      expiryDate: "25 Nov 2019",
      created: "16 Jul 2019",
      recruiterFee: 5000,
      referralFee: 50,
    },
    {
      title: "PM/QA Team Lead",
      applications: 75,
      status: "Posted",
      location: "Tokyo",
      expiryDate: "25 Nov 2019",
      created: "16 Jul 2019",
      recruiterFee: 5000,
      referralFee: 50,
    },
    {
      title: "PM/QA Team Lead",
      applications: 75,
      status: "Posted",
      location: "Tokyo",
      expiryDate: "25 Nov 2019",
      created: "16 Jul 2019",
      recruiterFee: 5000,
      referralFee: 50,
    },
    {
      title: "PM/QA Team Lead",
      applications: 75,
      status: "Posted",
      location: "Tokyo",
      expiryDate: "25 Nov 2019",
      created: "16 Jul 2019",
      recruiterFee: 5000,
      referralFee: 50,
    },
    {
      title: "PM/QA Team Lead",
      applications: 75,
      status: "Posted",
      location: "Tokyo",
      expiryDate: "25 Nov 2019",
      created: "16 Jul 2019",
      recruiterFee: 5000,
      referralFee: 50,
    },
    {
      title: "PM/QA Team Lead",
      applications: 75,
      status: "Posted",
      location: "Tokyo",
      expiryDate: "25 Nov 2019",
      created: "16 Jul 2019",
      recruiterFee: 5000,
      referralFee: 50,
    },
    {
      title: "PM/QA Team Lead",
      applications: 75,
      status: "Posted",
      location: "Tokyo",
      expiryDate: "25 Nov 2019",
      created: "16 Jul 2019",
      recruiterFee: 5000,
      referralFee: 50,
    },
    {
      title: "PM/QA Team Lead",
      applications: 75,
      status: "Posted",
      location: "Tokyo",
      expiryDate: "25 Nov 2019",
      created: "16 Jul 2019",
      recruiterFee: 5000,
      referralFee: 50,
    },
  ],

  jobPositionsRecruiter: [
    {
      img: publicLink + "/assets/jobPostings/logo-1.png",
      position: "UI/UX Designer",
      location: "Singapore",
      employmentType: "Full Time",
      fee: 500,
    },
    {
      img: publicLink + "/assets/jobPostings/logo-2.png",
      position: "Senior Business Analyst",
      location: "Singapore",
      employmentType: "Full Time",
      fee: 500,
    },
    {
      img: publicLink + "/assets/jobPostings/logo-3.png",
      position: "Senior UI Designer",
      location: "Singapore",
      employmentType: "Full Time",
      fee: 500,
    },
    {
      img: publicLink + "/assets/jobPostings/logo-4.png",
      position: "Account Manager",
      location: "Singapore",
      employmentType: "Full Time",
      fee: 500,
    },
    {
      img: publicLink + "/assets/jobPostings/logo-5.png",
      position: "Content Manager",
      location: "Singapore",
      employmentType: "Full Time",
      fee: 500,
    },
    {
      img: publicLink + "/assets/jobPostings/logo-6.png",
      position: "Sales Executive",
      location: "Singapore",
      employmentType: "Full Time",
      fee: 500,
    },
    {
      img: publicLink + "/assets/jobPostings/logo-7.png",
      position: "Android Developer",
      location: "Singapore",
      employmentType: "Full Time",
      fee: 500,
    },
    {
      img: publicLink + "/assets/jobPostings/logo-8.png",
      position: "Marketing Manager",
      location: "Singapore",
      employmentType: "Full Time",
      fee: 500,
    },
    {
      img: publicLink + "/assets/jobPostings/logo-9.png",
      position: "Sales Executive",
      location: "Singapore",
      employmentType: "Full Time",
      fee: 500,
    },
    {
      img: publicLink + "/assets/jobPostings/logo-10.png",
      position: "UI/UX Designer",
      location: "Singapore",
      employmentType: "Full Time",
      fee: 500,
    },
    {
      img: publicLink + "/assets/jobPostings/logo-11.png",
      position: "Senior UI Designer",
      location: "Singapore",
      employmentType: "Full Time",
      fee: 500,
    },
    {
      img: publicLink + "/assets/jobPostings/logo-12.png",
      position: "Sales Executive",
      location: "Singapore",
      employmentType: "Full Time",
      fee: 500,
    },
  ],

  applicants: [
    {
      img: publicLink + "/assets/users/philip-hawkins.png",
      name: "Philip Hawkins",
      status: "For Interview",
      positionApplied: "Team Lead",
      location: "Singapore",
      date: "10 Sep 2019",
      source: "Direct",
      isRecommended: true,
      isNew: true,
      employer: "Google",
      contact: "Timothy Warren",
    },
    {
      img: publicLink + "/assets/users/wade-fisher.png",
      name: "Wade Fisher",
      status: "Submitted",
      positionApplied: "Angular JS Developer",
      location: "Hong kong",
      date: "22 Sep 2019",
      source: "Recruiter",
      isRecommended: true,
      isNew: true,
      employer: "Cloudflare",
      contact: "Julie Lane",
    },

    {
      img: publicLink + "/assets/users/jennie-miles.png",
      name: "Jennie Miles",
      status: "Submitted",
      positionApplied: "QA Engineer",
      location: "Tokyo",
      date: "19 Sep 2019",
      source: "Referral",
      isRecommended: true,
      isNew: true,
      employer: "Cloudflare",
      contact: "Stella Fox",
    },
    {
      img: publicLink + "/assets/users/morris-howard.png",
      name: "Morris Howard",
      status: "On hold",
      positionApplied: "Project Manager",
      location: "London",
      date: "24 Sep 2019",
      source: "Referral",
      isRecommended: true,
      isNew: true,
      employer: "Cloudflare",
      contact: "Albert Cooper",
    },
    {
      img: publicLink + "/assets/users/terra-mccoy.png",
      name: "Terra Mccoy",
      status: "Submitted",
      positionApplied: "Lead Xamarin Developer",
      location: "Singapore",
      date: "19 Jul 2019",
      source: "Recruiter",
      isRecommended: true,
      isNew: false,
      employer: "Xiaomi",
      contact: "Kyle Webb",
    },
    {
      img: publicLink + "/assets/users/scarlett-simmons.png",
      name: "Scarlett Simmons",
      status: "For Interview",
      positionApplied: "Project Manager",
      location: "Shanghai",
      date: "27 Aug 2019",
      source: "Direct",
      isRecommended: true,
      isNew: false,
      employer: "FedEx",
      contact: "Pat Hawkins",
    },
    {
      img: publicLink + "/assets/users/timothy-mccoy.png",
      name: "Timothy Mccoy",
      status: "Rejected",
      positionApplied: "Quality Assurance Engineer",
      location: "Singapore",
      date: "29 Jul 2019",
      source: "Direct",
      isRecommended: false,
      isNew: false,
      employer: "Google",
      contact: "Irma Watson",
    },
    {
      img: publicLink + "/assets/users/robert-jones.png",
      name: "Robert Jones",
      status: "For Interview",
      positionApplied: "IT Recruiter",
      location: "New York",
      date: "12 Jul 2019",
      source: "Recruiter",
      isRecommended: false,
      isNew: false,
      employer: "Google",
      contact: "Floyd Flores",
    },
    {
      img: publicLink + "/assets/users/arlene-black.png",
      name: "Arlene Black",
      status: "On hold",
      positionApplied: "Lead QA",
      location: "Dubai",
      date: "11 Jun 2019",
      source: "Referral",
      isRecommended: false,
      isNew: false,
      employer: "Xiaomi",
      contact: "Gladys Fox",
    },
    {
      img: publicLink + "/assets/users/bernard-nguyen.png",
      name: "Bernard Nguyen",
      status: "On hold",
      positionApplied: "Full Stack Web Developer",
      location: "London",
      date: "10 Jun 2019",
      source: "Recruiter",
      isRecommended: false,
      isNew: false,
      employer: "Google",
      contact: "Jacob Steward",
    },
  ],

  status: {
    forInterview: publicLink + "/assets/status/for-interview.png",
    submitted: publicLink + "/assets/status/submitted.png",
    onHold: publicLink + "/assets/status/on-hold.png",
    rejected: publicLink + "/assets/status/rejected.png",
    closed: publicLink + "/assets/status/closed.png",
    posted: publicLink + "/assets/status/posted.png",
  },

  ovals: {
    purple: publicLink + "/assets/oval-purple.png",
    lavender: publicLink + "/assets/oval-lavender.png",
    peach: publicLink + "/assets/oval-peach.png",
  },

  nav: {
    logo: publicLink + "/assets/candid-i-logo-orange.png",
  },

  profile: {
    fullName: "Jonathan Wong",
    role: "Recruiter",
    location: "Newton, Singapore",
    connections: 300,
    cover: publicLink + "/assets/profile/profile-cover.png",
    image: publicLink + "/assets/profile/profile-image.png",
    experiences: [
      {
        logo: publicLink + "/assets/profile/profile-exp-1.png",
        title: "Team Leader",
        company: "Lorem Ipsum Inc.",
        duration: "Jan 2018 - Present",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis",
      },
      {
        logo: publicLink + "/assets/profile/profile-exp-2.png",
        title: "Senior UX Designer",
        company: "Dolor Sit Amet",
        duration: "Aug 2016 - Jan 2018",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium",
      },
      {
        logo: publicLink + "/assets/profile/profile-exp-3.png",
        title: "Graphic Designer",
        company: "Lorem Ipsum Inc.",
        duration: "Sep 2013 - Aug 2016",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi.",
      },
    ],
    expertise: [
      {
        skill: "Graphic Design",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque",
      },
      {
        skill: "Motion Graphics",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque",
      },
      {
        skill: "UI/UX Design",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque",
      },
    ],
  },

  home: {
    dashedLine: publicLink + "/assets/dashed-line.png",
    hexagons: {
      pinkHexagon: publicLink + "/assets/hexagon-pink.svg",
      pinkHexagonHalf: publicLink + "/assets/hexagon-pink-half.png",
      blueOrangehexagon: publicLink + "/assets/hexagon-blue-orange.jpg",
    },
    landingPageCard: {
      landingCard: publicLink + "/assets/landing-card.svg",
      landingCard2: publicLink + "/assets/landing-card2.svg",
      landingCard3: publicLink + "/assets/landing-card3.svg",
    },

    hero: publicLink + "/assets/hero-illus.svg",
    hero2: publicLink + "/assets/hero-2.png",
    images: {
      employer: publicLink + "/assets/employer-illus.svg",
      recruiter: publicLink + "/assets/recruiter-illus.svg",
      jobseeker: publicLink + "/assets/jobseeker-illus.svg",
    },
    banner: publicLink + "/assets/home-banner-btm.png",
    videoFrame: publicLink + "/assets/landing-page-video-frame.png",
  },

  sidebar: {
    login: publicLink + "/assets/login-img.png",
    signupMain: publicLink + "/assets/signup-main-img.png",
    employerSignup: publicLink + "/assets/employer-signup-img.png",
    recruiterSignup: publicLink + "/assets/recruiter-signup-img.png",
    jobseekerSignup: publicLink + "/assets/jobseeker-signup-img.png",
  },

  roles: [
    {
      icon: publicLink + "/assets/employer-icon.svg",
      title: "Employer",
      homeDescription: "Hire the best talent faster",
      signupDescription: "You’re an employer looking to hire great talent",
    },
    {
      icon: publicLink + "/assets/recruiter-icon.svg",
      title: "Recruiter",
      homeDescription: "Make more placements, grow your network",
      signupDescription: "You’re a recruiter looking to make more placements",
    },
    {
      icon: publicLink + "/assets/jobseeker-icon.svg",
      title: "Jobseeker",
      homeDescription:
        "Find the best career opportunities, refer friends and win rewards",
      signupDescription:
        "You’re a jobseeker and you want to find a job or refer a friend",
    },
  ],

  latestInterviews: [
    {
      employer: publicLink + "/assets/companies/logo-google.png",
      position: "Project Manager",
      interviewer: {
        img: publicLink + "/assets/users/courtney-henry.png",
        name: "Courtney Henry",
        position: "HR Manager",
      },
      date: "7 Jul 2019",
      status: "2nd round",
    },
  ],

  icons: {
    nextArrow: publicLink + "/assets/next-arrow.svg",
  },
};

export default content;
