export const LANDING = "/welcome";
export const ABOUT = "/about";
export const AUTH = "/auth";
export const Services = "/services";
export const LOGIN = "/auth/login/:applicantId?/:jobId?/:type?/";
export const SIGN_UP = "/auth/signup";
export const SIGN_UP_RECRUITER = "/auth/signup/recruiter/:email?/:name?/:id?";
// export const SIGN_UP_JOBSEEKER = '/auth/signup/jobseeker';
export const SIGN_UP_JOBSEEKER_NO_PARAMS = "/auth/signup/jobseeker";
export const SIGN_UP_JOBSEEKER =
  "/auth/signup/jobseeker/:applicantId?/:jobId?/:type?";
// export const REDIRECT_TO_JOBSEEKER='/auth/signup/jobseeker/:applicantId?/:timing?'
export const SIGN_UP_EMPLOYER = "/auth/signup/employer";
export const APPLICANT_EMAIL_RESPONSE = "/email-response/:id";

export const PROFILE = "/profile";
export const VISIT_PROFILE = "/visit-profile";
export const USER_PROFILE = "/visit-profile/user=:id";
export const PASSWORD_FORGET = "/auth/pw-forget";
export const ADMIN = "/admin";
export const ADMIN_DETAILS = "/admin/:id";

export const JOBSEEKER = "/jobseeker";
export const RECRUITER = "/recruiter";

export const EMPLOYER = "/employer";

export const CREATE_JOB = "/jobs/create-job";
export const JOB_DETAILS = "/jobs/job-detail=:id";

export const JOB_APPLICANT_DETAIL = "/applicants/detail/applicantId=:id";

// Subheader
export const HOME = "/";

export const JOBS = "/jobs";
export const DRAFTS = "/jobs/drafts";
export const APPLICANTS = "/applicants";
export const JOB_APPLICANTS_LIST = "/jobs/list-of-applicants/job-title=:id";
export const INTERVIEWS = "/interviews";
export const MY_REFERRALS = "/referrals";
export const RECRUITERS = "/recruiters";
export const RECRUITER_DETAILS = "/recruiters/recruiter-detail=:id";
export const ANALYTICS = "/analytics";
export const WALLET = "/wallet";
export const CHAT = "/chat";
export const PERFORMANCE = "/performance";

export const SETTINGS = "/settings";
export const SETTINGS_ACCOUNT = "/settings/account";
export const SETTINGS_NOTIFICATIONS = "/settings/notifications";
export const SETTINGS_PASSWORD = "/settings/password";
export const SETTINGS_BILLING = "/settings/billing";
export const SETTINGS_BILLING_SUCCESS = "/settings/billing/success";
export const SETTINGS_BILLING_CANCEL = "/settings/billing/cancel";
export const MY_HIRINGS = "/myhirings";

export const NETWORK = "/network";
export const NETWORK_CONNECTIONS = "/network/connections";
export const NETWORK_TEAMMATE = "/network/teammate";
export const NETWORK_EVENTS = "/network/events";
export const NETWORK_LEADERBOARD = "/network/leaderboard";
export const NETWORK_DISCUSSIONS = "/network/discussions";

export const MESSAGES = "/messages";
export const EVENTS = "/eventdetails/event";

export const DISCUSSION_DETAILS = "/discussions/";

export const BLOG_DETAILS = "/blog/";

export const LANDING_BLOGS = "/discover-blogs/";


export const CONTACT_MANAGMENT = "/contact-management/";

export const CUSTOMIZE_ASSISTANT = "/customize-assistant"

export const SCHEDULE_CALL = "/schedule-call"