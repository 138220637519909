import { FIREBASE_ACTIONS } from "../reducers/constants";

const firebaseObject = (firebase) => {
  return {
    type: FIREBASE_ACTIONS.FIREBASE_OBJECT,
    payload: firebase,
  };
};

const setUser = (user) => {
  return {
    type: FIREBASE_ACTIONS.AUTH_USER,
    payload: user,
  };
};

const getApplicants = () => {
  return {
    type: FIREBASE_ACTIONS.GET_APPLICANTS,
  };
};

const getApplicantsSuccess = (applicants) => {
  return {
    type: FIREBASE_ACTIONS.GET_APPLICANTS_SUCCESS,
    payload: applicants,
  };
};

const getUsers = () => {
  return {
    type: FIREBASE_ACTIONS.GET_USERS,
  };
};

const getUsersSuccess = (users) => {
  return {
    type: FIREBASE_ACTIONS.GET_USERS_SUCCESS,
    payload: users,
  };
};

const getJobs = () => {
  return {
    type: FIREBASE_ACTIONS.GET_JOBS,
  };
};

const getJobsSuccess = (jobs) => {
  return {
    type: FIREBASE_ACTIONS.GET_JOBS_SUCCESS,
    payload: jobs,
  };
};

const getInterviews = () => {
  return {
    type: FIREBASE_ACTIONS.GET_INTERVIEWS,
  };
};

const getInterviewsSuccess = (interviews) => {
  return {
    type: FIREBASE_ACTIONS.GET_INTERVIEWS_SUCCESS,
    payload: interviews,
  };
};

const clearAll = () => {
  return {
    type: FIREBASE_ACTIONS.CLEAR_FIREBASE_DATA,
  };
};

export {
  getApplicants,
  firebaseObject,
  setUser,
  getUsers,
  getJobs,
  getApplicantsSuccess,
  getJobsSuccess,
  getUsersSuccess,
  getInterviews,
  getInterviewsSuccess,
  clearAll,
};
