import { RATING_CONSTANTS } from "./constants";

const INITIAL_RATING_STATE = {
  ratingOpen: false,
  ratingUser: null,
};

export const ratingReducer = (state = INITIAL_RATING_STATE, action) => {
  switch (action.type) {
    case RATING_CONSTANTS.RATING_OPEN:
      return { ...state, ratingOpen: true };
    case RATING_CONSTANTS.RATING_CLOSE:
      return { ...state, ratingOpen: false };
    case RATING_CONSTANTS.RATING_USER:
      return { ...state, ratingUser: action.payload };
    default:
      return state;
  }
};
