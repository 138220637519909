import { RECRUITER_NOTIFICATION_CONSTANTS } from "./constants";

const INITIAL_RATING_STATE = {
  userExist : []
};

export const recruiterNotificationReducer = (state = INITIAL_RATING_STATE, action) => {
  switch (action.type) {
    case RECRUITER_NOTIFICATION_CONSTANTS.USER_EXIST:
      return { ...state, userExist: [...state.userExist, action.payload] };
    default:
      return state;
  }
};
