import { takeLatest, all, put, fork, select } from "redux-saga/effects";
import {
  getApplicantsSuccess,
  getInterviewsSuccess,
  getJobsSuccess,
  getUsersSuccess,
} from "./actions/firebaseActions";
import { FIREBASE_ACTIONS } from "./reducers/constants";

function* loadApplicants() {
  let state = yield select();
  let firebase = state.firebaseReducer.firebase;
  try {
    const applicants = yield new Promise((resolve) =>
      firebase.applicants().orderByChild("createdAt").on("value", resolve)
    );
    if (applicants.val() !== null) {
      const applicantList = Object.keys(applicants.val()).map((key) => ({
        ...applicants.val()[key],
        uid:key
      }));
      yield put(getApplicantsSuccess(applicantList));
    } else {
      yield put(getApplicantsSuccess(null));
    }
  } catch (error) {}
}

export function* onCallGetApplicants() {
  yield takeLatest(FIREBASE_ACTIONS.GET_APPLICANTS, loadApplicants);
}

function* loadJobs() {
  let state = yield select();
  let firebase = state.firebaseReducer.firebase;
  try {
    const jobs = yield new Promise((resolve) =>
      firebase.jobs().orderByChild("createdAt").on("value", resolve)
    );
    if (jobs.val() !== null) {
      const jobList = Object.keys(jobs.val()).map((key) => ({
        ...jobs.val()[key],
        uid:key
      }));
      yield put(getJobsSuccess(jobList));
    } else {
      yield put(getJobsSuccess(null));
    }
  } catch (error) {}
}

export function* onCallGetJobs() {
  yield takeLatest(FIREBASE_ACTIONS.GET_JOBS, loadJobs);
}

function* loadUsers() {
  let state = yield select();
  let firebase = state.firebaseReducer.firebase;
  try {
    const users = yield new Promise((resolve) =>
      firebase.users().orderByChild("createdAt").on("value", resolve)
    );
    if (users.val() !== null) {
      const userList = Object.keys(users.val()).map((key) => ({
        ...users.val()[key],
        uid:key
      }));
      yield put(getUsersSuccess(userList));
    } else {
      yield put(getUsersSuccess(null));
    }
  } catch (error) {}
}

export function* onCallGetUsers() {
  yield takeLatest(FIREBASE_ACTIONS.GET_USERS, loadUsers);
}

export function* loadInterviews() {
  let state = yield select();
  let firebase = state.firebaseReducer.firebase;
  try {
    const interviews = yield new Promise((resolve) =>
      firebase.interview().orderByChild("createdAt").on("value", resolve)
    );
    if (interviews.val() !== null) {
      const interviewDetails = Object.keys(interviews.val()).map((key) => ({
        ...interviews.val()[key],
        uid:key
      }));
      yield put(getInterviewsSuccess(interviewDetails));
    } else {
      yield put(getInterviewsSuccess(null));
    }
  } catch (error) {}
}

export function* onCallGetInterviews() {
  yield takeLatest(FIREBASE_ACTIONS.GET_INTERVIEWS, loadInterviews);
}

const sagas = [
  fork(onCallGetApplicants),
  fork(onCallGetJobs),
  fork(onCallGetUsers),
  fork(onCallGetInterviews),
];

export default function* rootSaga() {
  yield all([...sagas]);
}
