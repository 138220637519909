const CHAT_CONSTANTS = {
  CHAT_USER: "CHAT_USER",
  CHAT_OPEN: "CHAT_OPEN",
  CHAT_MINIMIZE: "CHAT_MINIMIZE",
  CHAT_ALLMESSAGES: "CHAT_ALLMESSAGES",
  CHAT_SIDEBARDELETE: "CHAT_SIDEBARDELETE",
};
const RATING_CONSTANTS = {
  RATING_OPEN: "RATING_OPEN",
  RATING_CLOSE: "RATING_CLOSE",
  RATING_USER: "RATING_USER",
};

const RECRUITER_NOTIFICATION_CONSTANTS = {
  USER_EXIST: "USER_EXIST",
};

const FIREBASE_ACTIONS = {
  FIREBASE_OBJECT: "FIREBASE_OBJECT",
  AUTH_USER: "AUTH_USER",
  GET_APPLICANTS: "GET_APPLICANTS",
  GET_APPLICANTS_SUCCESS: "GET_APPLICANTS_SUCCESS",
  GET_USERS: "GET_USERS",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_JOBS: "GET_JOBS",
  GET_JOBS_SUCCESS: "GET_JOBS_SUCCESS",
  GET_INTERVIEWS: "GET_INTERVIEWS",
  GET_INTERVIEWS_SUCCESS: "GET_INTERVIEWS_SUCCESS",
  FIND_JOB: "FIND_JOB",
  FIND_USER: "FIND_USER",
  CLEAR_FIREBASE_DATA:"CLEAR_FIREBASE_DATA",

  RECRUITER_APPLICANTS: "RECRUITER_APPLICANTS",
  EMPLOYER_JOBS: "EMPLOYER_JOBS"
};

export {
  CHAT_CONSTANTS,
  RATING_CONSTANTS,
  RECRUITER_NOTIFICATION_CONSTANTS,
  FIREBASE_ACTIONS,
};
