import React, { useState } from "react";
import { Link } from "react-router-dom";
import content from "../../content";
import * as ROUTES from "../../constants/routes";
import Contact from "./contact-modal";
import "./style.scss";
import Main from "../../images/services.svg";
import Main1 from "../../images/services1.svg";
import Main2 from "../../images/services2.svg";

function Services() {
  const [openDemo, setOpenDemo] = useState(false);

  const closeModal = () => setOpenDemo(false);
  return (
    <div className="flex flex-col">
      <div className="services-cover">
        <header class="px-20 sm:px-36 md:px-36 text-sm lg:text-base xl:text-lg flex flex-col text-center md:flex-row md:text-left md:justify-between py-4 md:items-baseline w-full justify-around">
          <div className="mb-2 md:mb-0 mx-auto md:mx-0">
            <Link to={ROUTES.LANDING}>
              <img
                src={content.logo}
                alt="Candid-i logo"
                className="w-32 md:w-40 lg:w-auto"
              />
            </Link>
          </div>
          <div className="my-auto">
            <Link to="/about" className="hover:underline mr-6 lg:mr-12">
              Why CI
            </Link>
            <Link
              to="/services"
              className="hover:underline lg:mr-12 xl:mr-12 md:mr-12  whitespace-nowrap font-semibold"
            >
              How It Works
            </Link>
            <div className="mt-10 block sm:inline">
              <button
                onClick={() => setOpenDemo(true)}
                className="text-white font-bold bg-blueBtn rounded-lg whitespace-nowrap px-4 py-2 hover:bg-blue-800 "
              >
                Request a Demo
              </button>
            </div>
          </div>
        </header>

        <Contact open={openDemo} close={closeModal} />

        <div className="flex flex-col justify-center">
          <div className="col-start-4 col-span-5 py-10 flex flex-row justify-center">
            <h4>
              Home <span className="text-xl text-yellow-600"> / </span> How It
              Works
            </h4>
          </div>
          <div className="pb-20 flex flex-row justify-center">
            <h1 className="text-5xl font-semibold">How It Works</h1>
          </div>
        </div>
      </div>
      <div className="flex xs:flex-row sm:flex-row md:flex-row lg:flex-row xl:flex-row flex-col-reverse xs:mt-30 sm:mt-30 md:mt-30 lg:mt-30 mt-10 gap-16">
        <img
          src={Main}
          className="sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 w-full"
          alt=""
        />
        <div className="flex p-8 sm:w-1/2 md:w-1/2 lg:w-1/2 w-4/5 justify-center items-center">
          <div className="flex flex-col sm:w-98 md:w-98 lg:w-98 xs:w-98 w-full h-auto">
            <h1 className="text-4xl font-extrabold mb-2">
              Connect with Expert Recruiters
            </h1>
            <div className="flex flex-col mt-8">
              <p className="font-extrabold text-xl text-blueBtn">
                Post your recruiting needs
              </p>
              <p className=" text-base font-normal">
                Describe the role and candidate you need to recruit
              </p>
            </div>
            <div className="flex flex-col mt-8">
              <p className="font-extrabold text-xl text-blueBtn">
                Review responses
              </p>
              <p className=" text-base font-normal">
                Start receiving responses from recruiters who can help you find
                talent
              </p>
            </div>
            <div className="flex flex-col mt-8">
              <p className="font-extrabold text-xl text-blueBtn">
                Select your recruiter
              </p>
              <p className=" text-base font-normal">
                Compare experience, feedback, prior performance and price to
                select the best recruiter for you. Make a small project
                prepayment to get started
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex xs:flex-row-reverse sm:flex-row-reverse md:flex-row-reverse lg:flex-row-reverse xl:flex-row-reverse flex-col-reverse xs:mt-30 sm:mt-30 md:mt-30 lg:mt-30 mt-10">
        <img
          src={Main1}
          className="sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 w-full"
          alt=""
        />
        <div className="flex p-8 sm:w-1/2 md:w-1/2 lg:w-1/2 w-4/5 justify-center items-center">
          <div className="flex flex-col sm:w-92 md:w-92 lg:w-92 xs:w-92 w-full h-auto">
            <h1 className="text-4xl font-extrabold mb-2">
              Find the right talent anytime, anywhere
            </h1>
            <div className="flex flex-col mt-8">
              <p className="font-extrabold text-xl text-blueBtn">
                Scope your project
              </p>
              <p className=" text-base font-normal">
                Outsource the full recruiting process or just parts of it. Your
                recruiter does the hard work
              </p>
            </div>
            <div className="flex flex-col mt-8">
              <p className="font-extrabold text-xl text-blueBtn">
                Receive shortlisted candidates
              </p>
              <p className=" text-base font-normal">
                Your recruiter submits online candidate profiles. Rate, comment
                and share them with colleagues. It's all online
              </p>
            </div>
            <div className="flex flex-col mt-8">
              <p className="font-extrabold text-xl text-blueBtn">
                Interview and test online
              </p>
              <p className=" text-base font-normal">
                Review recorded video interviews and Candidate tests results
                before interviewing the best candidates in person
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex xs:flex-row sm:flex-row md:flex-row lg:flex-row xl:flex-row flex-col-reverse xs:mt-30 sm:mt-30 md:mt-30 lg:mt-30 mt-10">
        <img
          src={Main2}
          className="sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 w-full"
          alt=""
        />
        <div className="flex p-8 justify-center items-center sm:w-1/2 md:w-1/2 lg:w-1/2 w-4/5 ">
          <div className="flex flex-col sm:w-98 md:w-98 lg:w-98 xs:w-98 w-full h-auto">
            <h1 className="text-4xl font-extrabold mb-2">Hassle free hiring</h1>
            <div className="flex flex-col mt-8">
              <p className="font-extrabold text-xl text-blueBtn">
                Hire the best candidate
              </p>
              <p className=" text-base font-normal">
                Select the best fit for your team. Have confidence with
                fact-based, best practice recruiting methodology
              </p>
            </div>
            <div className="flex flex-col mt-8">
              <p className="font-extrabold text-xl text-blueBtn">
                Pay your recruiter
              </p>
              <p className=" text-base font-normal">
                Pay online, only for hours worked. No success fees or trailing
                commissions
              </p>
            </div>
            <div className="flex flex-col mt-8">
              <p className="font-extrabold text-xl text-blueBtn">
                Leave feedback
              </p>
              <p className=" text-base font-normal">
                Review the performance of your recruiter for next time
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12 grid grid-cols-20 place-items-center mt-20 md:mt-32 lg:mt-52 md:mb-16 2xl:mb-24 h-96 relative flex-grow">
        <div className="col-start-2 col-end-20 grid grid-cols-20 h-56 md:h-72 w-full 2xl:h-96 shadow-2xl z-10 bg-cover bg-home-pattern landing-cover">
          <div className="col-start-2 col-span-17 lg:col-end-14 top-36 sm:top-28 xl:top-36 2xl:py28 absolute">
            <h2 className="text-xl md:text-3xl  lg:pl-8 font-extrabold text-white 2xl:text-5xl">
              Join us as we build the recruiting network for a distributed and
              decentralized workforce.
            </h2>
          </div>
          <div className="col-start-2 lg:col-start-15 sm:top-64 lg:top-44 xl:top-48 absolute">
            <Link
              to="/signup"
              className="text-white font-bold bg-orangeBtn hover:bg-orange-500 rounded-xl whitespace-nowrap px-4 py-3 2xl:text-xl"
            >
              Coming Soon - Sign Up
            </Link>
          </div>
        </div>
        <div className=" col-span-12 absolute -top-40 md:-top-52 2xl:-top-72 pt-48 items-center grid-cols-12 grid z-0">
          <div className="col-start-1 col-end-4">
            <img src={content.home.hexagons.blueOrangehexagon} alt="" />
          </div>

          <div className="col-start-10 md:col-start-11 col-end-13 pb-20 md:pb-60 2xl:pb-80 pl-12">
            <img src={content.home.hexagons.pinkHexagon} alt="" />
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="flex flex-col justify-center items-center place-items-center">
        <Link to="/" className="z-20">
          <img
            src={content.logo}
            alt="Candid-i logo"
            className="w-32 md:w-40 lg:w-auto"
          />
        </Link>

        <div className="text-sm md:text-base flex flex-row col-span-12 pt-4 md:pt-8 lg:pt-12 pb-16 lg:pb-24 text-default">
          {" "}
          <Link to="/about" className="hover:underline">
            About Us
          </Link>
          <Link to="/services" className="px-12 lg:px-16 hover:underline">
            Services
          </Link>
          <Link to="/contact" className="hover:underline">
            Contact
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Services;
