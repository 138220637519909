import React from "react";
import ReactDOM from "react-dom";

import "./index.css";

import App from "./App";
import Firebase, { FirebaseContext } from "./components/Firebase";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { MuiThemeProvider, createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary:{
     main: "#F7931D"
    },
    secondary: {
      main: '#E33E7F'
    }
  }
});

ReactDOM.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <MuiThemeProvider theme={theme}>
      <FirebaseContext.Provider value={new Firebase()}>
        <App />
      </FirebaseContext.Provider>
    </MuiThemeProvider>
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById("root")
);
