import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import * as ROUTES from "./constants/routes";
import { withAuthentication } from "./components/Session";
import ReactLoader from "./components/pages/Loader";
import Services from "./components/pages/services";
import "@stripe/stripe-js";

const Landing = lazy(() => import("./components/pages/landing"));
const About = lazy(() => import("./components/pages/about"));
const Auth = lazy(() => import("./components/pages/auth"));
const Main = lazy(() => import("./components/pages/main"));
const EmailResponse = lazy(() => import("./components/pages/email-response"));
// const Chat = lazy(()=>import('./components/pages/Chat'));
const Success = lazy(() => import("./components/pages/success"));
const Cancel = lazy(() => import("./components/pages/cancel"));
const Blog = lazy(() => import("./components/pages/blogs"));
const BlogDetails = lazy(() => import("./components/blog/blog-detail"));

const App = () => (
  <div className="font-openSans">
    <Router>
      <Suspense fallback={<ReactLoader />}>
        <Route exact path={ROUTES.LANDING} component={Landing} />
        <Route path={ROUTES.ABOUT} component={About} />
        <Route path={ROUTES.Services} component={Services} />
        <Route path={ROUTES.LANDING_BLOGS} component={Blog} />
        <Route path={ROUTES.BLOG_DETAILS} component={BlogDetails} />
        <Route
          path={ROUTES.APPLICANT_EMAIL_RESPONSE}
          component={EmailResponse}
        />
        <Route path={ROUTES.AUTH} component={Auth} />
        <Route path={ROUTES.HOME} component={Main} />
        {/* <Route path={ROUTES.CHAT} component={Chat}/> */}
        <Route path={ROUTES.SETTINGS_BILLING_SUCCESS} component={Success} />
        <Route path={ROUTES.SETTINGS_BILLING_CANCEL} component={Cancel} />
      </Suspense>
    </Router>
  </div>
);

export default withAuthentication(App);
