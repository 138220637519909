import React from "react";
import { renderToString } from "react-dom/server";
import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";
import axios from "axios";
import * as ROUTES from "../../../src/constants/routes";

const prodConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_CONFIRMATION_EMAIL_REDIRECT: "https://candid-i-1c9fb.web.app/",
};

const devConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_CONFIRMATION_EMAIL_REDIRECT: "http://localhost:3000/",
};

const config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;
    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();

    /*creating firestore database for message */
    this.myFirestore = app.firestore();
    this.timestamp = app.firestore.FieldValue.serverTimestamp();

    this.googleProvider = new app.auth.GoogleAuthProvider();

    // this.firebaseFunctions = app.functions();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: window.location.origin,
    });

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignOut = () => this.auth.signOut();

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .once("value")
          .then((snapshot) => {
            const dbUser = snapshot.val();
            if (!dbUser) {
              return;
            }

            // default empty roles
            if (!dbUser?.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = (uid) => this.db.ref(`users/${uid}`);

  users = () => this.db.ref("users");

  // *** Work Experience API ***

  workExperience = (uid, uid2) =>
    this.db.ref(`users/${uid}/workExperiences/${uid2}`);

  workExperiences = (uid) => this.db.ref(`users/${uid}/workExperiences`);

  // *** Work Expertise API ***

  expertise = (uid, uid2) => this.db.ref(`users/${uid}/expertises/${uid2}`);

  expertises = (uid) => this.db.ref(`users/${uid}/expertises`);

  // *** Message API ***

  message = (uid) => this.db.ref(`messages/${uid}`);

  messages = () => this.db.ref(`messages`);

  // *** Create Jobs API ***

  job = (uid) => this.db.ref(`jobs/${uid}`);

  jobs = () => this.db.ref(`jobs`);

  jobApplicant = (uid, uid2) => this.db.ref(`jobs/${uid}/applications/${uid2}`);

  jobApplications = (uid) => this.db.ref(`jobs/${uid}/applications`);

  // *** News Feed ***

  feed = (uid) => this.db.ref(`feeds/${uid}`);

  feeds = () => this.db.ref(`feeds`);

  // interview details

  interview = () => this.db.ref("interviewDetails/");
  interviewUID = (uid) => this.db.ref(`interviewDetails/${uid}`);

  //for videos
  videos_database = () => this.db.ref(`videos`);
  video_database_ref = (uid) => this.db.ref(`videos/${uid}`);

  feed_comment_1 = (uid) => this.db.ref(`feed_comments_1/${uid}`);

  feed_comments_1 = () => this.db.ref(`feed_comments_1`);

  feed_comment_2 = (uid) => this.db.ref(`feed_comments_2/${uid}`);

  feed_comments_2 = () => this.db.ref(`feed_comments_2`);

  // *** Applicants ***

  applicant = (uid) => this.db.ref(`applicants/${uid}`);

  applicants = () => this.db.ref(`applicants`);

  // *** Drafts ***

  draft = (uid) => this.db.ref(`drafts/${uid}`);

  drafts = () => this.db.ref(`drafts`);

  // *** Storage API ***

  cvs = () => this.storage.ref("cvs");

  cv = (cvName) => this.storage.ref(`cvs/${cvName}`);

  invoices = () => this.storage.ref("invoices");

  invoice = (invoiceData) => this.storage.ref(`invoices/${invoiceData}`);

  avatars = () => this.storage.ref("avatars");

  avatar = (avatarName) => this.storage.ref(`avatars/${avatarName}`);

  feed_store = (fileName) => this.storage.ref(`feeds/${fileName}`);

  feeds_store = () => this.storage.ref("feeds");

  coverPhotos = () => this.storage.ref("coverPhotos");

  coverPhoto = (coverPhotoName) =>
    this.storage.ref(`coverPhotos/${coverPhotoName}`);

  //For videos
  videos_storage = () => this.storage.ref(`videos`);
  video_file = (video) => this.storage.ref(`videos/${video}`);

  //For Events Images
  event_files = () => this.storage.ref(`eventImages`);
  event_file = (image) => this.storage.ref(`eventImages/${image}`);

  // For Comment Images
  comment_images = () => this.storage.ref("commentImages");
  comment_image = (image) => this.storage.ref(`commentImages/${image}`);

  //For Discussion Images
  discussion_files = () => this.storage.ref("discussionImages");
  discussion_file = (image) => this.storage.ref(`discussionImages/${image}`);

  //For Blog Images
  blog_files = () => this.storage.ref("blogImages");
  blog_file = (image) => this.storage.ref(`blogImages/${image}`);

  // 1. Mail for interview schedule

  email_notification = (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "api-key":
          "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",
      },
    };

    const testBtns =
      data?.schedule_to &&
      data?.schedule_to.map((item, index) => {
        let dateNew = item.date.replaceAll("/", "-");
        return (
          <>
            Date available : {`${dateNew}`}
            <a
              href={
                data.consent === "no"
                  ? `${window.location.origin}/auth/signup/jobseeker/${data.applicantId}/jobId=${data.jobId}/${item?.timeStart}-${item?.timeEnd} date=${dateNew}`
                  : `${window.location.origin}/auth/login/${data.applicantId}/jobId=${data.jobId}/${item?.timeStart}-${item?.timeEnd} date=${dateNew}`
              }
            >
              <button
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 20,
                  backgroundColor: "#F7911D",
                  width: "100px",
                  height: "27px",
                  borderRadius: "13px",
                  borderWidth: "0px",
                  cursor: "pointer",
                  color: "white",
                }}
              >
                {` ${item?.timeStart}-${item?.timeEnd} `}
              </button>
            </a>
            <br />
          </>
        );
      });

    const ele = React.createElement("div", {}, [testBtns]);
    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Candin-I Admin",
            email: "notifications@candid-i.com",
          },
          to: [
            {
              name: data.name_to,
              email: data.email_to,
            },
          ],
          subject: `Interview Confirmation for ${data?.jobName.toUpperCase()}`,
          htmlContent: `
          <html>
          <head></head>
          <body style="margin: 0; padding: 0; background: #f4f4f5; font-family: sans-serif">
            <table style="width: 740px;
              margin: auto;
              padding: 25px;">
              <tr>
              <td>
                <div style="text-align: center;">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/candid-i-1c9fb.appspot.com/o/avatars%2Fcandid-i-logo.png?alt=media&token=7da6d72a-bb97-47ff-903b-84471c404e68"
                    style="margin-bottom: 0.5rem; width: 150px; height: auto;" alt='Candid CI' />
                </div>
              </td>
              </tr>
              <tr>
                <td style="text-align: center;">
                  <h2>Congratulations! You've got an interview Scheduled.</h2>
                </td>
              </tr>
          
              <tr>
                <td style="
                  color: #2d2d2d;
                  font-weight: 100;
                  font-size: 0.85rem;
                  margin: 0.25rem;
                  text-align: center;
                  padding-bottom: 15px;">
                  Dear ${data?.firstName}
                </td>
              </tr>
          
              <tr>
                <td style="
                  color: #2d2d2d;
                  font-weight: 100;
                  font-size: 0.85rem;
                  margin: 0.25rem;
                  text-align: center;
                  padding-bottom: 15px;
                ">
                  Congratulations on securing an interview with ${
                    data.employer
                  } Company Name for the role of Job <br />Role: ${data?.jobName.toUpperCase()}. Please find your interview
                  details
                  below – select your availability
                </td>
              </tr>
              <tr>
                <td style="margin-bottom: 0.5rem; text-align: center;">
                  ${renderToString(ele)}
                </td>
              </tr>
              <tr>
                <td>
                 <div
                 style="
                   background: #EC8937;
                   border-radius: 25px;
                   padding: 0.8rem 2rem;
                   color: white;
                   margin-left: 12rem;
                   margin-bottom: 2rem;
                   margin-top: 2rem;
                   cursor: pointer;
                   text-decoration: none;
                   text-align: center;
                   width: 36%;
                 "
               >
                 <a
                   style="text-decoration: none; color: white"
                   href="${data.schedule_to[0].link}"
                 >
                   Click here to join the meeting
                 </a>
               </div>
          
                </td>
              </tr>
              <tr>
                <td style="
                " href='${
                  data.consent === "no"
                    ? `https://candid-i-1c9fb.firebaseapp.com/auth/signup/jobseeker/${data.applicantId}/jobId=${data.jobId}/null date=null`
                    : `http://candid-i-1c9fb.firebaseapp.com/auth/login/${data.applicantId}/jobId=${data.jobId}/null date=null`
                }'>
                  <div
                  style="
                    background: #EC8937;
                    border-radius: 25px;
                    padding: 0.8rem 2rem;
                    color: white;
                    margin-left: 12rem;
                    margin-bottom: 2rem;
                    cursor: pointer;
                    text-decoration: none;
                    text-align: center;
                    width: 36%;
             
                  "
                >
                  <a
                    style="color: white; text-decoration: none"
                    href='${
                      data.consent === "no"
                        ? `https://candid-i-1c9fb.firebaseapp.com/auth/signup/jobseeker/${data.applicantId}/jobId=${data.jobId}/null date=null`
                        : `http://candid-i-1c9fb.firebaseapp.com/auth/login/${data.applicantId}/jobId=${data.jobId}/null date=null`
                    }'
                  >
                    Sign Up and complete your profile
                  </a>
                </div>
                </td>
              </tr>
              <tr>
                <td style="
                  color: #2d2d2d;
                  font-weight: 100;
                  margin: 0.25rem;
                  padding: 0;
                  text-align: center;
                ">
                  This link will expire in 24 hours. If you continue to have
                  problems<br />
                  please feel free to contact us at
                  <a href="" style="text-decoration: none;"><u style="text-decoration: none;">notifications@candid-i.com</u></a>.
                  <a href="" style="text-decoration: none;"><u style="text-decoration: none;">UNSUBSCRIBE</u></a>
                </td>
              </tr>
              <tr>
                <td style="color: #2d2d2d;
                  font-weight: 100;
                  font-size: 0.85rem;
                  margin: 0.25rem;
                  text-align: center;
                  padding-bottom: 15px;
                  font-size: 11px;">
                  Thanks
                </td>
              </tr>
            </table>
          </body>
          </html>
         `,
        },
        options
      )
      .then(
        (response) => {
          return response;
        },
        (error) => {
          return error;
        }
      );

    // console.log("email sent");
  };

  // 2. mail for consent of apllicant (from recruiter)

  consent_email_notification_recruiter = (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "api-key":
          "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",
      },
    };
    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Candid-I Admin",
            email: "notifications@candid-i.com",
          },
          to: [
            {
              name: data.name_to,
              // email: "rajatjobseeker@yopmail.com",
              email: data.email_to,
            },
          ],
          subject: "Your profile has been submitted on CI network",
          htmlContent: `
          <html>
          <head></head>
          <body
            style="margin: 0; padding: 0; background: #F4F4F5; font-family: sans-serif"
          >
            <table style="width: 740px; margin: auto; padding: 25px">
              <tr>
            <td>
          <div style="text-align: center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/candid-i-1c9fb.appspot.com/o/avatars%2Fcandid-i-logo.png?alt=media&token=7da6d72a-bb97-47ff-903b-84471c404e68"
              style="margin-bottom: 0.5rem; width: 150px; height: auto"
              alt="Candid CI"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td style="text-align: center">
          <h2>Please Provide Consent</h2>
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2D2D2D;
            font-weight: 100;
            font-size: 0.85rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
          "
        >
          Hi ${data.name_to}
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2D2D2D;
            font-weight: 100;
            font-size: 0.85rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
          "
        >
          Congratulations your resume has been submitted for the job of
          ${data.jobtitle} role at<br />
          ${data.employer} by recuiter ${data.recruiter} via candid-I
          recruitment network.
        </td>
      </tr>
      <tr>
        <td style="text-align: center;
        padding: 2rem;">
         
          
              <a
                href="${window.location.origin}/auth/signup/jobseeker/${data.applicantId}/null/yes"
                style="
                  background: #EC8937;
                  border-radius: 25px;
                  padding: 0.8rem 2rem;
                  color: white;
                  margin: 0.25rem;
                  cursor: pointer;
                  text-decoration: none;
                "
                >Yes
              </a>
            
              <a
                href="${window.location.origin}/auth/signup/jobseeker/${data.applicantId}/null/no"
                style="
                  background: #EC8937;
                  border-radius: 25px;
                  padding: 0.8rem 2rem;
                  color: white;
                  margin: 0.25rem;
                  cursor: pointer;
                  text-decoration: none;
                "
                >No
              </a>
            
          
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2D2D2D;
            font-weight: 100;
            font-size: 0.85rem;
            margin-top: 4rem;
            text-align: center;
          "
        >
          <b
            >By clicking YES you give consent for the recruiter to exclusively
            represent you for the opportunity.</b
          >
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2D2D2D;
            font-weight: 100;
            font-size: 0.85rem;
            padding-top: 0.4rem;
            text-align: center;
          "
        >
          We look forward to your speedy reply.
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2D2D2D;
            font-weight: 100;
            padding-top: 1.5rem;
            text-align: center;
            font-size: 10px;
          "
        >
          This link will expire in 24 hours. If you continue to have problems<br />
          please feel free to contact us at
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">notifications@candid-i.com</u></a
          >.
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">UNSUBSCRIBE</u></a
          >
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2D2D2D;
            font-weight: 100;
            font-size: 0.85rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
            font-size: 11px;
          "
        >
          Thanks
        </td>
      </tr>
    </table>
  </body>
</html>
          `,
        },
        options
      )
      .then(
        (response) => {
          // console.log("response>>>>>>>>", response);

          return response;
        },
        (error) => {
          // console.log(error.response);
          return error;
        }
      );
  };

  // 3. mail for raising a dispute

  dispute_email_notification = (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "api-key":
          "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",
      },
    };
    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Candid-I Admin",
            email: "notifications@candid-i.com",
          },
          to: [
            {
              name: data?.name,
              email: data?.email,
              // email: "rajatjobseeker@yopmail.com",
            },
          ],
          subject: `Disputing Information in ${data?.subject}`,
          htmlContent: `
          <html>
  <head></head>
  <body
    style="margin: 0; padding: 0; background: #f4f4f5; font-family: sans-serif"
  >
    <table
      style="width: 740px; margin: auto; padding: 25px;"
    >
      <tr>
        <td>
          <div style="text-align: center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/candid-i-1c9fb.appspot.com/o/avatars%2Fcandid-i-logo.png?alt=media&token=7da6d72a-bb97-47ff-903b-84471c404e68"
              style="margin-bottom: 0.5rem; width: 150px; height: auto"
              alt="Candid CI"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td style="text-align: center">
          <h2>Received your Concern</h2>
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 400;
            font-size: 1rem;
            padding: 1rem;
            text-align: center;
          "
        >
          Dear ${data?.name}
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;
            font-size: 0.85rem;
            margin: 0.25rem;
            padding: 0;
            text-align: center;
          "
        >
          We have received your dispute message and the team will investigate
          and contact you within 72 hours.
        </td>
      </tr>

      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 400;
            font-size: 1rem;
            padding: 1rem;
            text-align: center;
          "
        >
          Thank You,
        </td>
      </tr>

      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 400;
            font-size: 1rem;
            margin: 0.25rem;
            padding: 1rem;
            text-align: center;
          "
        >
          Candid-I Admin
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;

            text-align: center;
            font-size: 10px;
          "
        >
          This link will expire in 24 hours. If you continue to have problems<br />
          please feel free to contact us at
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">notifications@candid-i.com</u></a
          >.
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">UNSUBSCRIBE</u></a
          >
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;
            font-size: 0.85rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
            font-size: 11px;
          "
        >
          Thanks
        </td>
      </tr>
    </table>
  </body>
</html>

          `,
        },
        options
      )
      .then(
        (response) => {
          console.log("response>>>>>>>>", response);
          return response;
        },
        (error) => {
          console.log(error.response);
          return error;
        }
      );
  };

  // 4. mail for selection of candidate for particular job

  accept_notif = (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "api-key":
          "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",
      },
    };

    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Candin-I Admin",
            email: "notifications@candid-i.com",
          },
          to: [
            {
              email: data.email_to,
              // email: "rajatjobseeker@yopmail.com",
              name: data.name_to,
            },
          ],
          subject: "Congratulations! You have been hired!",
          htmlContent: `
          <html>
          <head></head>
          <body
            style="margin: 0; padding: 0; background: #f4f4f5; font-family: sans-serif"
          >
            <table
              style="width: 740px; margin: auto; padding: 25px;"
            >
              <tr>
                <td>
                  <div style="text-align: center">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/candid-i-1c9fb.appspot.com/o/avatars%2Fcandid-i-logo.png?alt=media&token=7da6d72a-bb97-47ff-903b-84471c404e68"
                      style="margin-bottom: 0.5rem; width: 150px; height: auto"
                      alt="Candid CI"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td style="text-align: center">
                  <h2>Congratulations! You have been selected!!</h2>
                </td>
              </tr>
              <tr>
                <td
                  style="
                    color: #2d2d2d;
                    font-weight: 400;
                    font-size: 1rem;
                    padding: 0.5rem;
                    text-align: center;
                  "
                >
                  Hi ${data.name_to}
                </td>
              </tr>
              <tr>
                <td
                  style="
                    color: #2d2d2d;
                    font-weight: 100;
                    font-size: 0.85rem;
                    padding: 1rem;
                    text-align: center;
                  "
                >
                  Congratulations you have been selected for the job offer!
                </td>
              </tr>
        
              <tr>
                <td
                  style="
                    color: #2d2d2d;
                    font-weight: 100;
                    text-align: center;
                    font-size: 10px;
                  "
                >
                  This link will expire in 24 hours. If you continue to have problems<br />
                  please feel free to contact us at
                  <a href="" style="text-decoration: none"
                    ><u style="text-decoration: none">notifications@candid-i.com</u></a
                  >.
                  <a href="" style="text-decoration: none"
                    ><u style="text-decoration: none">UNSUBSCRIBE</u></a
                  >
                </td>
              </tr>
              <tr>
                <td
                  style="
                    color: #2d2d2d;
                    font-weight: 100;
                    font-size: 0.85rem;
                    text-align: center;
                    font-size: 11px;
                  "
                >
                  Thanks
                </td>
              </tr>
            </table>
          </body>
        </html>
        
          `,
        },
        options
      )
      .then(
        (response) => {
          // console.log("email sent accptnotif function", response);

          return response;
        },
        (error) => {
          // console.log(error.response);
          return error;
        }
      );
  };

  // 5. alert mail employer for candidate submission from recruiter

  alert_employer_recruiter = (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "api-key":
          "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",
      },
    };
    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Candin-I Admin",
            email: "notifications@candid-i.com",
          },
          to: [
            {
              email: data.email_to,
              // email: "rajatjobseeker@yopmail.com",
              name: data.name_to,
            },
          ],
          subject: "New Candidate Alert",
          htmlContent: `
          <html>

<head></head>

<body style="margin: 0; padding: 0; background: #F4F4F5; font-family: sans-serif">
  <table style="width: 740px; margin: auto; padding: 25px">
    <tr>
      <td>
        <div style="text-align: center">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/candid-i-1c9fb.appspot.com/o/avatars%2Fcandid-i-logo.png?alt=media&token=7da6d72a-bb97-47ff-903b-84471c404e68"
            style="margin-bottom: 0.5rem; width: 150px; height: auto" alt="Candid CI" />
        </div>
      </td>
    </tr>
    <tr>
      <td style="text-align: center">
        <h2>New Candidate Alert!</h2>
      </td>
    </tr>
    <tr>
      <td style="
            color: #2D2D2D;
            font-weight: 500;
            font-size: 1.05rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
          ">
        Hi ${data.name_to}
      </td>
    </tr>
    <tr>
      <td style="
            color: #2D2D2D;
            font-weight: 100;
            font-size: 0.85rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
          ">
        ${data.recruiter} has submitted a new candidate for ${data.jobTitle}
        position!
      </td>
    </tr>
    <tr>
      <td style="text-align: center;
        padding: 1rem;">


        <a href="${window.location.origin}/auth/signup/jobseeker/${data.applicantId}/null/yes" style="
                  background: #EC8937;
                  border-radius: 25px;
                  padding: 0.8rem 2rem;
                  color: white;
                  margin: 0.25rem;
                  cursor: pointer;
                  text-decoration: none;
                "> Check now
        </a>

      


      </td>
    </tr>
    
   
    <tr>
      <td style="
            color: #2D2D2D;
            font-weight: 100;
            padding-top: 1.5rem;
            text-align: center;
            font-size: 10px;
          ">
        This link will expire in 24 hours. If you continue to have problems<br />
        please feel free to contact us at
        <a href="" style="text-decoration: none"><u style="text-decoration: none">notifications@candid-i.com</u></a>.
        <a href="" style="text-decoration: none"><u style="text-decoration: none">UNSUBSCRIBE</u></a>
      </td>
    </tr>
    <tr>
      <td style="
            color: #2D2D2D;
            font-weight: 100;
            font-size: 0.85rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
            font-size: 11px;
          ">
        Thanks
      </td>
    </tr>
  </table>
</body>

</html>
          `,
        },
        options
      )
      .then(
        (response) => {
          // console.log("response>>>>>>>>", response);
          return response;
        },
        (error) => {
          // console.log(error.response);
          return error;
        }
      );
  };

  // 6. alert mail employer for candidate submission from jobseeker

  alert_employer_jobseeker = (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "api-key":
          "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",
      },
    };
    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Candin-I Admin",
            email: "notifications@candid-i.com",
          },
          to: [
            {
              email: data.email_to,
              // email: "rajatjobseeker@yopmail.com",
              name: data.name_to,
            },
          ],
          subject: "New Candidate Alert",
          htmlContent: `
          <html>
          <head></head>
          <body
            style="margin: 0; padding: 0; background: #f4f4f5; font-family: sans-serif"
          >
            <table
              style="width: 740px; margin: auto; padding: 25px;"
            >
              <tr>
                <td>
                  <div style="text-align: center">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/candid-i-1c9fb.appspot.com/o/avatars%2Fcandid-i-logo.png?alt=media&token=7da6d72a-bb97-47ff-903b-84471c404e68"
                      style="margin-bottom: 0.5rem; width: 150px; height: auto"
                      alt="Candid CI"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td style="text-align: center">
                  <h2>New Candidate Alert!</h2>
                </td>
              </tr>
              <tr>
                <td
                  style="
                    color: #2d2d2d;
                    font-weight: 400;
                    font-size: 1rem;
                    padding: 1rem;
                    text-align: center;
                  "
                >
                  Hi ${data.name_to}
                </td>
              </tr>
              <tr>
                <td
                  style="
                    color: #2d2d2d;
                    font-weight: 100;
                    font-size: 0.85rem;
                    text-align: center;
                  "
                >
                  ${data.name_to} has submitted a new candidate for ${data.jobTitle}
                  position!
                </td>
              </tr>
        
              <tr>
                <td style="padding: 2rem; text-align: center">
                  <a
                    style="
                      background: #ec8937;
                      border-radius: 25px;
                      padding: 0.8rem 2rem;
                      color: white;
                      cursor: pointer;
                      text-decoration: none;
                    "
                    href="https://candid-i-1c9fb.firebaseapp.com/auth/login"
                  >
                    Check now
                  </a>
                </td>
              </tr>
        
              <tr>
                <td
                  style="
                    color: #2d2d2d;
                    font-weight: 100;
        
                    text-align: center;
                    font-size: 10px;
                  "
                >
                  This link will expire in 24 hours. If you continue to have problems<br />
                  please feel free to contact us at
                  <a href="" style="text-decoration: none"
                    ><u style="text-decoration: none">notifications@candid-i.com</u></a
                  >.
                  <a href="" style="text-decoration: none"
                    ><u style="text-decoration: none">UNSUBSCRIBE</u></a
                  >
                </td>
              </tr>
              <tr>
                <td
                  style="
                    color: #2d2d2d;
                    font-weight: 100;
                    font-size: 0.85rem;
                    margin: 0.25rem;
                    text-align: center;
                    padding-bottom: 15px;
                    font-size: 11px;
                  "
                >
                  Thanks
                </td>
              </tr>
            </table>
          </body>
        </html>
        
        `,
        },
        options
      )
      .then(
        (response) => {
          // console.log("response>>>>>>>>", response);
          return response;
        },
        (error) => {
          // console.log(error.response);
          return error;
        }
      );
  };

  // 7. mail for consent of applicant (from jobseeker for a referred candidate)

  consent_email_notification_jobseeker_referral = (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "api-key":
          "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",
      },
    };
    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Candid-I Admin",
            email: "notifications@candid-i.com",
          },
          to: [
            {
              name: data?.name_to,
              email: data?.email_to,
              // email: "rajatjobseeker@yopmail.com",
            },
          ],
          subject: "Your profile has been submitted on CI network",
          htmlContent: `
          <html>
          <head></head>
          <body
            style="margin: 0; padding: 0; background: #F4F4F5; font-family: sans-serif"
          >
            <table style="width: 740px; margin: auto; padding: 25px">
              <tr>
            <td>
          <div style="text-align: center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/candid-i-1c9fb.appspot.com/o/avatars%2Fcandid-i-logo.png?alt=media&token=7da6d72a-bb97-47ff-903b-84471c404e68"
              style="margin-bottom: 0.5rem; width: 150px; height: auto"
              alt="Candid CI"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td style="text-align: center">
          <h2>Please Provide Consent</h2>
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2D2D2D;
            font-weight: 100;
            font-size: 0.85rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
          "
        >
          Hi ${data.name_to}
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2D2D2D;
            font-weight: 100;
            font-size: 0.85rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
          "
        >
          Congratulations your resume has been submitted for the job of
          ${data.jobtitle} role at<br />
          ${data.employer} by recuiter ${data.recruiter} via candid-I
          recruitment network.
        </td>
      </tr>
      <tr>
        <td style="color: #2d2d2d; font-weight: 100; font-size: 0.85rem; margin: 0.25rem; text-align: center; padding-bottom: 15px;">
          
            Do you give your consent for ${data.employer} to submit your details
            and represent you to the above named company and opportunity
        </td>
      </tr>
      <tr>
        <td style="text-align: center;
        padding: 2rem;">
         
          
              <a
                href="${window.location.origin}/auth/signup/jobseeker/${data.applicantId}/null/yes"
                style="
                  background: #EC8937;
                  border-radius: 25px;
                  padding: 0.8rem 2rem;
                  color: white;
                  margin: 0.25rem;
                  cursor: pointer;
                  text-decoration: none;
                "
                >Yes
              </a>
            
              <a
                href="${window.location.origin}/auth/signup/jobseeker/${data.applicantId}/null/no"
                style="
                  background: #EC8937;
                  border-radius: 25px;
                  padding: 0.8rem 2rem;
                  color: white;
                  margin: 0.25rem;
                  cursor: pointer;
                  text-decoration: none;
                "
                >No
              </a>
            
          
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2D2D2D;
            font-weight: 100;
            font-size: 0.85rem;
            margin-top: 4rem;
            text-align: center;
          "
        >
          <b
            >By clicking YES you give consent for the recruiter to exclusively
            represent you for the opportunity.</b
          >
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2D2D2D;
            font-weight: 100;
            font-size: 0.85rem;
            padding-top: 0.4rem;
            text-align: center;
          "
        >
          We look forward to your speedy reply.
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2D2D2D;
            font-weight: 100;
            padding-top: 1.5rem;
            text-align: center;
            font-size: 10px;
          "
        >
          This link will expire in 24 hours. If you continue to have problems<br />
          please feel free to contact us at
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">notifications@candid-i.com</u></a
          >.
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">UNSUBSCRIBE</u></a
          >
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2D2D2D;
            font-weight: 100;
            font-size: 0.85rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
            font-size: 11px;
          "
        >
          Thanks
        </td>
      </tr>
    </table>
  </body>
</html>
        `,
        },
        options
      )
      .then(
        (response) => {
          // console.log("response>>>>>>>>", response);
          return response;
        },
        (error) => {
          // console.log(error.response);
          return error;
        }
      );
  };

  // 8. alert mail employer for candidate submission from jobseeker as referral

  alert_employer_referral = (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "api-key":
          "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",
      },
    };
    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Candin-I Admin",
            email: "notifications@candid-i.com",
          },
          to: [
            {
              name: data.name_to,
              // email: 'rajatjobseeker@yopmail.com',
              email: data.email_to,
            },
          ],
          subject: "New Candidate Alert",
          htmlContent: `
          <html>
  <head></head>
  <body
    style="margin: 0; padding: 0; background: #f4f4f5; font-family: sans-serif"
  >
    <table
      style="width: 740px; margin: auto; padding: 25px;"
    >
      <tr>
        <td>
          <div style="text-align: center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/candid-i-1c9fb.appspot.com/o/avatars%2Fcandid-i-logo.png?alt=media&token=7da6d72a-bb97-47ff-903b-84471c404e68"
              style="margin-bottom: 0.5rem; width: 150px; height: auto"
              alt="Candid CI"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td style="text-align: center">
          <h2>New Candidate Alert!</h2>
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 400;
            font-size: 1rem;
            padding: 1rem;
            text-align: center;
          "
        >
          Hi ${data.name_to}
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;
            font-size: 0.85rem;
            text-align: center;
          "
        >
          ${data.referredBy} has referred a new candidate for ${data.jobTitle}
          position!
        </td>
      </tr>

      <tr>
        <td style="padding: 2rem; text-align: center">
          <a
            style="
              background: #ec8937;
              border-radius: 25px;
              padding: 0.8rem 2rem;
              color: white;
              cursor: pointer;
              text-decoration: none;
            "
            href="https://candid-i-1c9fb.firebaseapp.com/auth/login"
          >
            Check now
          </a>
        </td>
      </tr>

      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;

            text-align: center;
            font-size: 10px;
          "
        >
          This link will expire in 24 hours. If you continue to have problems<br />
          please feel free to contact us at
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">notifications@candid-i.com</u></a
          >.
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">UNSUBSCRIBE</u></a
          >
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;
            font-size: 0.85rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
            font-size: 11px;
          "
        >
          Thanks
        </td>
      </tr>
    </table>
  </body>
</html>

          
          `,
        },
        options
      )
      .then(
        (response) => {
          // console.log("response>>>>>>>>", response);
          return response;
        },
        (error) => {
          // console.log(error.response);
          return error;
        }
      );
  };

  // 9. Selected for Job offer

  accept_notif = (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "api-key":
          "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",
      },
    };

    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Candin-I Admin",
            email: "notifications@candid-i.com",
          },
          to: [
            {
              email: data.email_to,
              // email: "rajatjobseeker@yopmail.com",
              name: data.name_to,
            },
          ],
          subject: "Congratulations! You have been hired!",
          htmlContent: `
          <html>
          <head></head>
          <body
            style="margin: 0; padding: 0; background: #F4F4F5; font-family: sans-serif"
          >
            <table style="width: 740px; margin: auto; padding: 25px">
              <tr>
            <td>
          <div style="text-align: center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/candid-i-1c9fb.appspot.com/o/avatars%2Fcandid-i-logo.png?alt=media&token=7da6d72a-bb97-47ff-903b-84471c404e68"
              style="margin-bottom: 0.5rem; width: 150px; height: auto"
              alt="Candid CI"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td style="text-align: center">
          <h2>Congratulations! You have been Selected</h2>
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2D2D2D;
            font-weight: 500;
            font-size: 1.05rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
          "
        >
          Hi ${data.name_to}
        </td>
      </tr>
     
      <tr>
        <td
          style="
            color: #2D2D2D;
            font-weight: 100;
            font-size: 0.85rem;
            padding-top: 0.4rem;
            text-align: center;
          "
        >
        Congratulations you have been selected for the job offer!
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2D2D2D;
            font-weight: 100;
            padding-top: 1.5rem;
            text-align: center;
            font-size: 10px;
          "
        >
          This link will expire in 24 hours. If you continue to have problems<br />
          please feel free to contact us at
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">notifications@candid-i.com</u></a
          >.
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">UNSUBSCRIBE</u></a
          >
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2D2D2D;
            font-weight: 100;
            font-size: 0.85rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
            font-size: 11px;
          "
        >
          Thanks
        </td>
      </tr>
    </table>
  </body>
</html>
          `,
        },
        options
      )
      .then(
        (response) => {
          // console.log("email sent accptnotif function", response);

          return response;
        },
        (error) => {
          // console.log(error.response);
          return error;
        }
      );
  };

  // 10. Send Invoice to employer

  sendInvoiceToEmployer = (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "api-key":
          "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",
      },
    };

    const { stripeUrl, currency, invoiceUrl, invoiceFileName, pdfDownloadUrl } =
      data;

    const paymentButton = `<a href='${stripeUrl}'><button
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 20,
                  backgroundColor: "#F7911D",
                  width: "100px",
                  height: "27px",
                  borderRadius: "13px",
                  borderWidth: "0px",
                  cursor: "pointer",
                  color: "white",
                }}             
                >
                Pay Now
              </button></a>
            <br />`;

    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Candin-I Admin",
            email: "notifications@candid-i.com",
          },
          to: [
            {
              email: data.email_to,
              // email: "rajatjobseeker@yopmail.com",
              name: data.name_to,
            },
          ],
          subject: `Candid-I Invoice for ${data?.jobName}`,
          htmlContent: `
          <html>
  <head></head>
  <body
    style="margin: 0; padding: 0; background: #f4f4f5; font-family: sans-serif"
  >
    <table
      style="width: 600px; margin: auto; padding: 75px;"
    >
      <tr>
        <td>
          <div style="text-align: center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/candid-i-1c9fb.appspot.com/o/avatars%2Fcandid-i-logo.png?alt=media&token=7da6d72a-bb97-47ff-903b-84471c404e68"
              style="margin-bottom: 0.5rem; width: 150px; height: auto"
              alt="Candid CI"
            />
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <h2>Candid-I Invoice for ${data?.jobName}</h2>
        </td>
      </tr>

      <tr>
        <td style="color: #a9a9a9; padding-bottom: 1rem">
          Invoice from Stripe Shop
        </td>
      </tr>

      <tr>
        <td>${data.currency} &nbsp; ${data.recruiter_fees}</td>
      </tr>

      <tr>
        <td style="color: #a9a9a9; margin-top: -15px">Due February 5, 2022</td>
      </tr>

      <tr>
        <td>
          <hr style="border-color: #cdd0cb; margin-top: 0.7rem" />
        </td>
      </tr>

      <tr>
        <td style="color: #a9a9a9; padding: 0.3rem">
          <a
            href="${pdfDownloadUrl}"
            style="
              text-decoration: none;
              background: #164c7899;
              color: white;
              padding: 0.5rem;
              font-size: 0.75rem;
              border-radius: 0.85rem;
            "
            >Download invoice</a
          >
        </td>
      </tr>

      <tr>
        <td style="display: flex; font-size: small">
          <p style="color: #a9a9a9">To</p>
          <p style="margin-left: 40px">${data.recruiter_name}</p>
        </td>
      </tr>

      <tr>
        <td style="display: flex; font-size: small; margin-top: -1rem">
          <p style="color: #a9a9a9">From</p>
          <p style="margin-left: 25px">Stripe Shop</p>
        </td>
      </tr>

      <tr>
        <td style="margin-top: -1rem; display: flex; font-size: small">
          <p style="color: #a9a9a9">Memo</p>
          <p style="margin-left: 20px">Thank for your business!</p>
        </td>
      </tr>

      <tr>
        <td style="margin: 0rem 1rem">
          <a href="${stripeUrl}"
            ><button
              style="
                background-color: #154c79;
                border: none;
                color: white;
                padding: 10px 10px;
                text-align: center;
                font-size: 14px;
                margin-top: 1.8rem;
                margin-bottom: 3rem;
                border-radius: 5px;
                cursor: pointer;
                width: 400px;
              "
            >
              Pay this invoice
            </button>
          </a>
        </td>
      </tr>

      <tr>
        <td style="color: #a9a9a9; font-size: small; padding: 2rem 0rem">
          Invoice #26B34523-DRAFT
        </td>
      </tr>

      <tr
        style="display: flex; flex-direction: row; gap: 225px; font-size: small"
      >
        <td>
          Shoes <br />
          <span style="color: #a9a9a9">Qty 1</span>
        </td>
        <td>${data.recruiter_fees}</td>
      </tr>

      <tr
        style="
          display: flex;
          gap: 190px;
          font-size: small;
          padding-top: 1rem;
          margin-bottom: 3rem;
        "
      >
        <td>Amount due</td>
        <td>${data.recruiter_fees}</td>
      </tr>

      <tr>
        <td style="color: #a9a9a9; font-size: small; padding: 2rem 0rem">
          Questions? Contact us at <a href="">mpbagwell+test@stripe.com</a>
        </td>
      </tr>

      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;
            padding: 1.5rem;
            text-align: center;
            font-size: 10px;
          "
        >
          This link will expire in 24 hours. If you continue to have problems<br />
          please feel free to contact us at
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">notifications@candid-i.com</u></a
          >.
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">UNSUBSCRIBE</u></a
          >
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;
            font-size: 0.85rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
            font-size: 11px;
          "
        >
          Thanks
        </td>
      </tr>
    </table>
  </body>
</html>

          `,

          //     htmlContent: `<html><head></head><body>
          //     <div style='background-color:#D3D3D3;padding:30px;width:70%'><div style='background-color:#FFFFFF;padding:20px'>
          //     <img src='https://firebasestorage.googleapis.com/v0/b/candid-i-1c9fb.appspot.com/o/avatars%2Fcandid-i-logo.png?alt=media&token=7da6d72a-bb97-47ff-903b-84471c404e68' style='width:120px;height:60px' alt='Candid CI'/>
          // <h5>Hi ${data.name_to}</h5>
          // <p>Congratulations, on your new hire! Here are the details of your new hire's recruiter.</p>
          // <p>Recruiter Name : ${data.recruiter_name}</p>
          // <p>Recruiter Fees : ${data.recruiter_fees}</p>
          // ${paymentButton}
          // </div></div>
          // </body></html>`,
        },
        options
      )
      .then(
        (response) => {
          // console.log("email sent accptnotif function", response);
          return response;
        },
        (error) => {
          // console.log(error.response);
          return error;
        }
      );
  };

  // 11. Send Invite for platform

  send_invite = (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "api-key":
          "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",
      },
    };

    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Candin-I Admin",
            email: "notifications@candid-i.com",
          },
          to: [
            {
              email: data.email_to,
              // email: "rajatjobseeker@yopmail.com",
              name: data?.email_to?.split("@")[0],
            },
          ],
          subject: "You have been invited to Candid-I network!",
          htmlContent: `
          <html>
          <head></head>
          <body
            style="margin: 0; padding: 0; background: #f4f4f5; font-family: sans-serif"
          >
            <table
              style="width: 740px; margin: auto; padding: 25px;"
            >
              <tr>
                <td>
                  <div style="text-align: center">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/candid-i-1c9fb.appspot.com/o/avatars%2Fcandid-i-logo.png?alt=media&token=7da6d72a-bb97-47ff-903b-84471c404e68"
                      style="margin-bottom: 0.5rem; width: 150px; height: auto"
                      alt="Candid CI"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td style="text-align: center">
                  <h2>You have been invited!</h2>
                </td>
              </tr>
              <tr>
                <td
                  style="
                    color: #2d2d2d;
                    font-weight: 400;
                    font-size: 1rem;
                    padding: 1rem;
                    text-align: center;
                  "
                >
                  Hi ${data?.email_to?.split("@")[0]}
                </td>
              </tr>
              <tr>
                <td
                  style="
                    color: #2d2d2d;
                    font-weight: 100;
                    font-size: 0.85rem;
                    text-align: center;
                  "
                >
                  Hey! Your friend ${
                    data?.sender_name
                  }, has invited you to CI Platform.
                </td>
              </tr>
        
              <tr>
                <td style="padding: 2rem; text-align: center">
                  <a
                    style="
                      background: #ec8937;
                      border-radius: 25px;
                      padding: 0.8rem 2rem;
                      color: white;
                      margin: 1rem;
                      cursor: pointer;
                      text-decoration: none;
                    "
                    href="${
                      window.location.origin
                    }/auth/signup/recruiter/email=${data.email_to}/name=${
            data.name_to
          }/id=${data.user_id}"
                  >
                    Click here to Sign up
                  </a>
                </td>
              </tr>
        
              <tr>
                <td
                  style="
                    color: #2d2d2d;
                    font-weight: 100;
        
                    text-align: center;
                    font-size: 10px;
                  "
                >
                  This link will expire in 24 hours. If you continue to have problems<br />
                  please feel free to contact us at
                  <a href="" style="text-decoration: none"
                    ><u style="text-decoration: none">notifications@candid-i.com</u></a
                  >.
                  <a href="" style="text-decoration: none"
                    ><u style="text-decoration: none">UNSUBSCRIBE</u></a
                  >
                </td>
              </tr>
              <tr>
                <td
                  style="
                    color: #2d2d2d;
                    font-weight: 100;
                    font-size: 0.85rem;
                    margin: 0.25rem;
                    text-align: center;
                    padding-bottom: 15px;
                    font-size: 11px;
                  "
                >
                  Thanks
                </td>
              </tr>
            </table>
          </body>
        </html>
        
          `,
        },
        options
      )
      .then(
        (response) => {
          alert("Done");
          // console.log("email sent accptnotif function", response);

          return response;
        },
        (error) => {
          return error;
        }
      );
  };

  //12. Send Invoice to employer without stripe

  sendInvoiceToEmployerWithoutStripe = (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "api-key":
          "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",
      },
    };

    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Candin-I Admin",
            email: "notifications@candid-i.com",
          },
          to: [
            {
              email: data.email_to,
              // email: "rajatjobseeker@yopmail.com",
              name: data?.email_to?.split("@")[0],
            },
          ],
          subject: "Invoice Ready!",
          htmlContent: `
          <html>
  <head></head>
  <body
    style="margin: 0; padding: 0; background: #f4f4f5; font-family: sans-serif"
  >
    <table
      style="width: 780px; margin: auto; padding: 25px;
    >
      <tr>
        <td>
          <div style="text-align: center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/candid-i-1c9fb.appspot.com/o/avatars%2Fcandid-i-logo.png?alt=media&token=7da6d72a-bb97-47ff-903b-84471c404e68"
              style="margin-bottom: 0.5rem; width: 150px; height: auto"
              alt="Candid CI"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td style="text-align: center">
          <h2>Candid-I Invoice for ${data?.jobName}</h2>
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 400;
            font-size: 1rem;
            padding: 1rem;
            text-align: center;
          "
        >
          Hi ${data?.email_to?.split("@")[0]}
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;
            font-size: 0.85rem;
            text-align: center;
          "
        >
          Hey! You have hired ${data.recruiter_name}'s Candidate. And Your
          Invoice for that is ready. Feel free to check that out.
        </td>
      </tr>

      <tr>
        <td style="padding: 3rem; text-align: center">
          <a
            style="
              background: #ec8937;
              border-radius: 25px;
              padding: 1rem 2rem;
              color: white;
              margin: 1rem;
              cursor: pointer;
              text-decoration: none;
            "
            href="${window.location.origin}/auth/login"
          >
            Click here to login
          </a>
        </td>
      </tr>

      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;
            text-align: center;
            font-size: 10px;
          "
        >
          This link will expire in 24 hours. If you continue to have problems<br />
          please feel free to contact us at
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">notifications@candid-i.com</u></a
          >.
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">UNSUBSCRIBE</u></a
          >
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;
            font-size: 0.85rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
            font-size: 11px;
          "
        >
          Thanks
        </td>
      </tr>
    </table>
  </body>
</html>

      `,
        },
        options
      )
      .then(
        (response) => {
          // console.log("email sent accptnotif function", response);

          return response;
        },
        (error) => {
          return error;
        }
      );
  };

  //13. Send Connection Accepted Notification to user

  sendConnectionAcceptedToUser = (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "api-key":
          "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",
      },
    };

    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Candin-I Admin",
            email: "notifications@candid-i.com",
          },
          to: [
            {
              email: data.email_to,
              // email: "rajatjobseeker@yopmail.com",
              name: data?.name_to,
            },
          ],
          subject: "New Connection Alert!",
          htmlContent: `
          <html>
  <head></head>
  <body
    style="margin: 0; padding: 0; background: #f4f4f5; font-family: sans-serif"
  >
    <table
      style="width: 780px; margin: auto; padding: 25px;"
    >
      <tr>
        <td>
          <div style="text-align: center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/candid-i-1c9fb.appspot.com/o/avatars%2Fcandid-i-logo.png?alt=media&token=7da6d72a-bb97-47ff-903b-84471c404e68"
              style="margin-bottom: 0.5rem; width: 150px; height: auto"
              alt="Candid CI"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td style="text-align: center">
          <h2>New Connection Alert!</h2>
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 400;
            font-size: 1rem;
            padding: 1rem;
            text-align: center;
          "
        >
          Hi ${data?.name_to}
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;
            font-size: 0.85rem;
            text-align: center;
            padding: 1rem;
          "
        >
          ${data?.user} has accepted your connection request.
        </td>
      </tr>

      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;
            text-align: center;
            font-size: 10px;
          "
        >
          This link will expire in 24 hours. If you continue to have problems<br />
          please feel free to contact us at
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">notifications@candid-i.com</u></a
          >.
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">UNSUBSCRIBE</u></a
          >
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;
            font-size: 0.85rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
            font-size: 11px;
          "
        >
          Thanks
        </td>
      </tr>
    </table>
  </body>
</html>

          `,
        },
        options
      )
      .then(
        (response) => {
          // console.log("email sent accptnotif function", response);

          return response;
        },
        (error) => {
          return error;
        }
      );
  };

  //14. Send Add to favourites to recruiter

  sendAddToFavouriteEmail = (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "api-key":
          "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",
      },
    };

    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Candin-I Admin",
            email: "notifications@candid-i.com",
          },
          to: [
            {
              email: data.email_to,
              // email: "rajatjobseeker@yopmail.com",
              name: data?.name_to,
            },
          ],
          subject: "You are someone's favourite!",
          htmlContent: `
          <html>
  <head></head>
  <body
    style="margin: 0; padding: 0; background: #f4f4f5; font-family: sans-serif"
  >
    <table
      style="width: 740px; margin: auto; padding: 25px;"
    >
      <tr>
        <td>
          <div style="text-align: center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/candid-i-1c9fb.appspot.com/o/avatars%2Fcandid-i-logo.png?alt=media&token=7da6d72a-bb97-47ff-903b-84471c404e68"
              style="margin-bottom: 0.5rem; width: 150px; height: auto"
              alt="Candid CI"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td style="text-align: center">
          <h2>You are someone's favourite !</h2>
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 400;
            font-size: 1rem;
            padding: 1rem;
            text-align: center;
          "
        >
          Hi ${data?.name_to}
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;
            font-size: 0.85rem;
            text-align: center;
            padding: 1rem;
          "
        >
          ${data?.user} has added you to favourites.
        </td>
      </tr>

      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;
            text-align: center;
            font-size: 10px;
          "
        >
          This link will expire in 24 hours. If you continue to have problems<br />
          please feel free to contact us at
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">notifications@candid-i.com</u></a
          >.
          <a href="" style="text-decoration: none"
            ><u style="text-decoration: none">UNSUBSCRIBE</u></a
          >
        </td>
      </tr>
      <tr>
        <td
          style="
            color: #2d2d2d;
            font-weight: 100;
            font-size: 0.85rem;
            margin: 0.25rem;
            text-align: center;
            padding-bottom: 15px;
            font-size: 11px;
          "
        >
          Thanks
        </td>
      </tr>
    </table>
  </body>
</html>

          `,
        },
        options
      )
      .then(
        (response) => {
          // console.log("email sent accptnotif function", response);

          return response;
        },
        (error) => {
          return error;
        }
      );
  };

  //15. Send Unseen message notification

  messageUnseenNotification = (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "api-key":
          "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",
      },
    };

    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Candin-I Admin",
            email: "notifications@candid-i.com",
          },
          to: [
            {
              email: data.email_to,
              name: data?.name_to,
            },
          ],
          subject: "Message Awaits Notification!",
          htmlContent: `
          <html>

          <head></head>
          
          <body style="margin: 0; padding: 0; background: #f4f4f5; font-family: sans-serif">
            <table style="width: 740px; margin: auto; padding: 25px;">
              <tr>
                <td>
                  <div style="text-align: center">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/candid-i-1c9fb.appspot.com/o/avatars%2Fcandid-i-logo.png?alt=media&token=7da6d72a-bb97-47ff-903b-84471c404e68"
                      style="margin-bottom: 0.5rem; width: 150px; height: auto" alt="Candid CI" />
                  </div>
                </td>
              </tr>
              <tr>
                <td style="text-align: center">
                  <h2>1 new message awaits your response!</h2>
                </td>
              </tr>
              <tr>
                <td style="
                      color: #2d2d2d;
                      font-weight: 400;
                      font-size: 1rem;
                      padding: 1rem;
                      text-align: center;
                    ">
                  Hi ${data?.name_to}
                </td>
              </tr>
              <tr style="margin-bottom: 20px;">
                <td style="text-align: center; margin-bottom: 1rem;">
                <button style="background-color: orange; padding: 0.5rem; border-radius: 0.5rem; border-color: orange;">View Message</button>
                </td>
              </tr>
          
              <tr>
                <td style="
                      color: #2d2d2d;
                      font-weight: 100;
                      text-align: center;
                      font-size: 10px;
                      padding-top: 1rem;
                    ">
                  This link will expire in 24 hours. If you continue to have problems<br />
                  please feel free to contact us at
                  <a href="" style="text-decoration: none"><u style="text-decoration: none">notifications@candid-i.com</u></a>.
                  <a href="" style="text-decoration: none"><u style="text-decoration: none">UNSUBSCRIBE</u></a>
                </td>
              </tr>
              <tr>
                <td style="
                      color: #2d2d2d;
                      font-weight: 100;
                      font-size: 0.85rem;
                      margin: 0.25rem;
                      text-align: center;
                      padding-bottom: 15px;
                      font-size: 11px;
                    ">
                  Thanks
                </td>
              </tr>
            </table>
          </body>
          
          </html>

          `,
        },
        options
      )
      .then(
        (response) => {
          // console.log("email sent accptnotif function", response);

          return response;
        },
        (error) => {
          return error;
        }
      );
  };
}
export default Firebase;
