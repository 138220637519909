import { FIREBASE_ACTIONS } from "./constants";

const INITIAL_FIREBASE_STATE = {
  firebase: null,
  user: null,
  applicants: null,
  jobs: null,
  users: null,
  interviewDetails: null,
};

export const firebaseReducer = (state = INITIAL_FIREBASE_STATE, action) => {
  switch (action.type) {
    case FIREBASE_ACTIONS.FIREBASE_OBJECT:
      return { ...state, firebase: action.payload };
    case FIREBASE_ACTIONS.AUTH_USER:
      return { ...state, user: action.payload };
    case FIREBASE_ACTIONS.GET_APPLICANTS: {
      return state;
    }
    case FIREBASE_ACTIONS.GET_APPLICANTS_SUCCESS: {
      return { ...state, applicants: action.payload };
    }
    case FIREBASE_ACTIONS.GET_JOBS: {
      return state;
    }
    case FIREBASE_ACTIONS.GET_JOBS_SUCCESS: {
      return { ...state, jobs: action.payload };
    }
    case FIREBASE_ACTIONS.GET_USERS: {
      return state;
    }
    case FIREBASE_ACTIONS.GET_USERS_SUCCESS: {
      return { ...state, users: action.payload };
    }
    case FIREBASE_ACTIONS.GET_INTERVIEWS: {
      return state;
    }
    case FIREBASE_ACTIONS.GET_INTERVIEWS_SUCCESS: {
      return { ...state, interviewDetails: action.payload };
    }
    case FIREBASE_ACTIONS.CLEAR_FIREBASE_DATA: {
      return {
        user: null,
        applicants: null,
        jobs: null,
        users: null,
        interviewDetails: null,
      };
    }
    default:
      return state;
  }
};
