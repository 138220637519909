import { CHAT_CONSTANTS } from "./constants";

const INITIAL_STATE = {
  open: false,
  user: null,
  minimize: false,
  allMessages: [],
};

export const chatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHAT_CONSTANTS.CHAT_USER:
      return { ...state, user: action.payload };

    case CHAT_CONSTANTS.CHAT_OPEN:
      return { ...state, open: action.payload };

    case CHAT_CONSTANTS.CHAT_MINIMIZE:
      return { ...state, minimize: action.payload };

    case CHAT_CONSTANTS.CHAT_ALLMESSAGES:
      return { ...state, allMessages: action.payload };

    case CHAT_CONSTANTS.CHAT_SIDEBARDELETE:
      let index = state.allMessages.findIndex(
        (item) => item.id === action.payload
      );
      state.allMessages.splice(index, 1);
      return { ...state };

    default:
      return state;
  }
};
