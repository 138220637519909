import React from 'react'
import { Modal, Box } from '@material-ui/core'
import content from "../../content";
import DemoImage from "../../images/Demo.png"


function Contact(props) {
    return (
        <Modal
            open={props.open}
            onClose={() => props.close()}
            className="flex justify-center"
        >
            <Box className="bg-white w-4/5 sm:w-4/5 md:w-4/5 lg:4/5 xl:4/5 2xl:4/5 flex justify-between rounded-lg my-8 h-11/12 sm:h-auto md:h-auto lg:h-auto">
                <div className="w-1/3 hidden sm:block md:block lg:block h-10/12 bg-cover bg-center relative">
                    <img src={DemoImage} alt="demo-image" className="w-full rounded-l-lg object-cover h-auto sm:h-auto md:h-full" />
                    <div className='absolute bottom-0 p-9 flex flex-col text-left w-auto'>
                        <h1 className=" text-white font-semibold text-xl ">Leverage the network
                            to hire great talent</h1>
                        <h3 className='text-white mt-4 text-xs break-all'>Hire the best talent anytime, anywhere and enabling recruiters to monetize their professional networks and empowering jobseekers to find the best career opportunities.</h3>
                    </div>
                </div>
                <div className='w-full sm:w-full md:w-2/3'>
                    <div className="flex  items-center justify-between mx-10 mt-4">
                        <img
                            src={content.logo}
                            alt="Candid-i logo"
                            className="w-28 md:w-30 sm:w-30"
                        />
                        <button className="bg-white sm:font-sm md:font-sm font-xs ml-4 rounded-lg p-1 my-1 sm:my-1 md:my-2">Login</button>
                    </div>
                    <div className='flex flex-col mx-10 sm:mx-14 md:mx-14 lg:mx-14 sm:mt-3 md:mt-3 lg:mt-3 mt-5 h-4/5'>
                        <div className="mt-3">
                            <p className="sm:text-2xl md:text-2xl text-xl font-bold">
                                Schedule a Demo
                            </p>
                            <p className="mt-2 text-xs sm:block md:block hidden">
                                Add Your Professional Information
                            </p>
                        </div>
                        <form className='mt-3 w-full'>
                            <div className="mt-2 2xl:mt-6 shadow-lg bg-white rounded-lg font-bold p-2 sm:p-6 md:p-6 lg:p-6 ">
                                <div className="grid grid-rows-8 sm:gap-4 md:gap-4 lg:gap-4 gap-2">
                                    <div className="row-span-2 grid grid-cols-1">
                                        <label htmlFor="Full name" className="font-normal text-sm">
                                            <span>Your Full Name</span>
                                            <input
                                                aria-label="e.g. John Appleseed"
                                                type="text"
                                                className="w-full px-2 py-1 my-1 outline-none border rounded focus:ring-2 focus:ring-gray-700"
                                                placeholder="e.g. John Appleseed"
                                                name="Full name"
                                            />
                                        </label>
                                    </div>
                                    <div className="row-span-2 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-2 ">
                                        <label htmlFor="Location" className="font-normal text-sm">
                                            <span>Location</span>
                                            <input
                                                aria-label="e.g. London UK"
                                                type="text"
                                                className="w-full px-2 py-1 my-1 outline-none border rounded focus:ring-2 focus:ring-gray-700"
                                                placeholder="e.g. London UK"
                                                name="Location"
                                            />
                                        </label>
                                        <label htmlFor="Company" className="font-normal text-sm">
                                            <span>Company Name</span>
                                            <input
                                                aria-label="e.g: Lorem Ipsum Inc."
                                                type="text"
                                                className="w-full px-2 py-1 my-1 outline-none border rounded focus:ring-2 focus:ring-gray-700"
                                                placeholder="e.g: Lorem Ipsum Inc."
                                                name="Company"
                                            />
                                        </label>
                                    </div>
                                    <div className="row-span-2 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-2">
                                        <label htmlFor="Email Address" className="font-normal text-sm">
                                            <span>Email Address</span>
                                            <input
                                                aria-label="e.g: john@example.com"
                                                type="text"
                                                className="w-full px-2 py-1 my-1 outline-none border rounded focus:ring-2 focus:ring-gray-700"
                                                placeholder="e.g: john@example.com"
                                                name="Email Address"
                                            />
                                        </label>
                                        <label htmlFor="Phone Number" className="font-normal text-sm">
                                            <span>Phone Number</span>
                                            <input
                                                aria-label="e.g: +1-234-567-890"
                                                type="tel"
                                                className="w-full px-2 py-1 my-1 outline-none border rounded focus:ring-2 focus:ring-gray-700"
                                                placeholder="e.g: +1-234-567-890"
                                                name="phoneNumber"
                                            />
                                        </label>
                                    </div>
                                    <div className="flex flex-col">
                                        <label htmlFor="Full name" className="font-normal text-sm">
                                            <span>Select Your Role</span>
                                        </label>
                                        <select className="font-normal text-sm px-2 text-gray-400 py-1 my-1 outline-none border rounded focus:ring-2 focus:ring-gray-700">
                                            <option>Select The Role ...</option>
                                            <option>Employer</option>
                                            <option>Recruiter</option>
                                            <option>Job-Seeker</option>
                                        </select>
                                    </div>
                                    <div className="flex flex-col">
                                        <label htmlFor="Full name" className="font-normal text-sm">
                                            <span>Where do you hear about us?</span>
                                        </label>
                                        <select className="px-2 font-normal text-sm text-gray-400 py-1 my-1 outline-none border rounded focus:ring-2 focus:ring-gray-700">
                                            <option>From ...</option>
                                            <option>Facebook or Instagram</option>
                                            <option>Email or Newsletter</option>
                                            <option>Search Engine</option>
                                            <option>Word Of Mouth</option>
                                            <option>Other</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button className="mt-8 float-right px-3 py-2 text-sm lg:text-base 2xl:px-4 2xl:py-3 2xl:mt-14 bg-orangeBtn hover:bg-orange-500 text-white font-semibold rounded-md">
                                Submit
                            </button>
                        </form>
                    </div>
                    {/* <div className="h-1/2 mx-10 mt-2 sm:mt-2 md:mt-6 mb-2 sm:mb-2 md:mb-6">
                        <div>
                            <div className="flex flex-col">
                                <label className="mx-1 sm:mx-1 md:mx-2 py-2 px-3">Your Full Name</label>
                                <input type="text" className="h-10 w-auto sm:h-10 md:h-12 text-sm sm:text-sm md:text-base my-2 sm:my-2 md:my-4 mx-2 sm:mx-2 md:mx-4 py-2 px-3 border-2 rounded-lg border-gray-400 focus:outline-none focus:border-gray-400 sm:w-auto md:w-4/5"
                                    placeholder='e.g. John Appleseed' />
                            </div>
                            <div className="flex flex-col">
                                <label className="mx-1 sm:mx-1 md:mx-2 py-2 px-3">Location</label>
                                <input type="text" className="h-10 w-auto sm:h-10 md:h-12 text-sm sm:text-sm md:text-base my-2 sm:my-2 md:my-4 mx-2 sm:mx-2 md:mx-4 py-2 px-3 border-2 rounded-lg border-gray-400 focus:outline-none focus:border-gray-400 sm:w-auto md:w-4/5"
                                    placeholder='e.g. London UK' />
                            </div>
                            <div className="flex flex-col">
                                <label className="mx-1 sm:mx-1 md:mx-2 py-2 px-3">Company name</label>
                                <input type="text" className="h-10 w-auto sm:h-10 md:h-12 text-sm sm:text-sm md:text-base my-2 sm:my-2 md:my-4 mx-2 sm:mx-2 md:mx-4 py-2 px-3 border-2 rounded-lg border-gray-400 focus:outline-none focus:border-gray-400 sm:w-auto md:w-4/5"
                                    placeholder='e.g: Lorem Ipsum Inc.' />
                            </div>
                            <div className="flex flex-col">
                                <label className="mx-1 sm:mx-1 md:mx-2 py-2 px-3">Email Address</label>
                                <input type="text" className="h-10 w-auto sm:h-10 md:h-12 text-sm sm:text-sm md:text-base my-2 sm:my-2 md:my-4 mx-2 sm:mx-2 md:mx-4 py-2 px-3 border-2 rounded-lg border-gray-400 focus:outline-none focus:border-gray-400 sm:w-auto md:w-4/5"
                                    placeholder='e.g: john@example.com' />
                            </div>
                            <div className="flex flex-col">
                                <label className="mx-1 sm:mx-1 md:mx-2 py-2 px-3">Contact Number</label>
                                <input type="text" className="h-10 w-auto sm:h-10 md:h-12 text-sm sm:text-sm md:text-base my-2 sm:my-2 md:my-4 mx-2 sm:mx-2 md:mx-4 py-2 px-3 border-2 rounded-lg border-gray-400 focus:outline-none focus:border-gray-400 sm:w-auto md:w-4/5"
                                    placeholder='e.g: +1-234-567-890' />
                            </div>
                        </div>
                        <div>
                            <select
                                htmlFor="Gender"
                                className="mx-2 w-11/12 px-2 h-10 sm:h-10 md:h-12 my-2 sm:my-2 md:my-4 text-gray-400 border-2 rounded-lg border-gray-400 focus:outline-none focus:border-gray-400"
                            >
                                <option value="" >Select Your Country</option>
                                {countries?.map((item) => <option value={item?.code}>{item?.name}</option>)}
                            </select>
                            <select
                                htmlFor="Gender"
                                className="mx-2 w-11/12 px-2 h-10 sm:h-10 md:h-12 my-2 sm:my-2 md:my-4 text-gray-400 border-2 rounded-lg border-gray-400 focus:outline-none focus:border-gray-400"
                            >
                                <option value="">Select Your Role</option>
                                <option value="">Employer</option>
                                <option value="">Recruiter</option>
                                <option value="">Job Seeker</option>
                            </select>
                        </div>
                        <button className="w-24 h-10 bg-orange-400 text-white ml-4 border-2 border-orange-400 rounded-lg p-1 my-4 sm:my-4 md:my-8 ">Submit</button>
                    </div> */}
                </div>
            </Box>
        </Modal>
    )
}

export default Contact