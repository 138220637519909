import React from 'react';
import { Link } from 'react-router-dom';
import content from '../../content';
import * as ROUTES from '../../constants/routes';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const withEmailVerification = (Component) => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);
      this.state = { isSent: false };
    }

    onSendEmailVerification = async () => {
      this.setState({ isSent: true });
      this.props.firebase.doSendEmailVerification();
    };

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            needsEmailVerification(authUser) ? (
              <div className="bg-body flex flex-col h-screen">
                {/* Header */}
                <div className="grid grid-cols-12 py-6 bg-navyBlue text-white text-2xl border">
                  <Link to={ROUTES.HOME} className="col-start-2 col-end-6">
                    <img src={content.nav.logo} alt="Candid-i logo" />
                  </Link>
                  <div className="col-start-6 col-end-12 flex flex-row justify-end">
                    <div className="my-auto cursor-pointer">
                      <ExitToAppIcon onClick={this.props.firebase.doSignOut} />
                    </div>
                  </div>
                </div>

                {/* Email Verification Component */}
                <div className="my-auto overflow-hidden">
                  <div className="max-w-lg bg-white shadow-md rounded-lg overflow-hidden mx-auto">
                    <div className="py-4 px-8 mt-3">
                      {this.state.isSent ? (
                        <div className="flex flex-col mb-8">
                          <h3 className="text-gray-700 font-semibold text-2xl tracking-wide mb-2">
                            E-Mail confirmation sent to {authUser.email}
                          </h3>
                          <p className="text-gray-500 text-base">
                            Hi {authUser.firstName}! Please check your E-Mails (Spam folder
                            included) for a confirmation E-Mail. Refresh this page once you
                            confirmed your E-Mail.
                          </p>
                        </div>
                      ) : (
                        <div className="flex flex-col mb-8">
                          <h3 className="text-gray-700 font-semibold text-2xl tracking-wide mb-2">
                            Verify your Email {authUser.email}
                          </h3>
                          <p className="text-gray-500 text-base">
                            Hi {authUser.firstName}! Please check your E-Mails (Spam folder
                            included) for a confirmation E-Mail or send another confirmation E-Mail.
                          </p>
                        </div>
                      )}
                      <div className="py-4">
                        <button
                          type="button"
                          onClick={this.onSendEmailVerification}
                          disabled={this.state.isSent}
                          className={`block tracking-widest uppercase text-center shadow bg-indigo-600 hover:bg-indigo-700 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-10 rounded ${
                            this.state.isSent && 'opacity-50'
                          }`}
                        >
                          Send confirmation E-Mail
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

const needsEmailVerification = (authUser) =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData.map((provider) => provider.providerId).includes('password');

export default withEmailVerification;
