import { applyMiddleware, createStore } from "redux";
import reducers from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./firebase-sagas";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ['chatReducer','ratingReducer','firebaseReducer']
};

const sagaMiddleWare = createSagaMiddleware();
const middleware = [sagaMiddleWare];
const persistedReducer = persistReducer(persistConfig, reducers);

let store = createStore(persistedReducer, applyMiddleware(...middleware));
let persistor = persistStore(store);
sagaMiddleWare.run(rootSaga);
export { store, persistor };
